<template>
  <div
    class="back-to-top-container"
    :class="{
      invisible: hide,
      'back-to-top-coverage-list-cart-summary': coverageListCart && coverageListCart.length > 0,
    }"
  >
    <button class="back-to-top-button" @click="scrollToTop" data-testid="scroll-to-top-button">
      <Icon name="arrow-up" size="lg" class="back-to-top-arrow" />
    </button>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { mapState } from "vuex";

export default {
  name: "ScrollToTop",
  components: { Icon },
  data() {
    return {
      hide: true,
    };
  },
  computed: {
    ...mapState(["coverageListCart"]),
  },
  mounted() {
    window.addEventListener("scroll", this.windowScrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScrollListener);
  },
  methods: {
    windowScrollListener() {
      if (window.pageYOffset > 50) {
        this.hide = false;
      } else {
        this.hide = true;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.back-to-top-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top-coverage-list-cart-summary {
  bottom: 120px;
}

@media screen and (max-width: 800px) {
  .back-to-top-coverage-list-cart-summary {
    bottom: 110px;
  }
}

.back-to-top-button {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--BaseButton-BackgroundColor);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.back-to-top-arrow {
  margin-bottom: 1px;
  color: var(--BaseButton-Color);
  font-size: 16px;
  font-weight: normal;
}

.back-to-top-button:focus {
  outline: none;
  border: 1px solid #4d90fe;
  -webkit-box-shadow: 0 0 5px #4d90fe;
  box-shadow: 0 0 5px #4d90fe;
  border-radius: 100%;
}
</style>
