import get from "lodash.get";

/**
 * State reducer pattern for managing the current state of a data table and an event that occurs in the data table. All
 * functions in here return a new copy of the state; this means any additional transformations required by the consumer
 * can be applied before passing it back to the data table.
 */
const DataTableStateReducer = {
  /**
   * Default reducer function for getting new state based on a column requesting that its sorting behavior changes.
   *
   * @param {Object} state - Object containing the following properties:
   *   - headers: Array of {@link HeaderConfiguration}
   *   - data: Array of objects representing the data of the data table.
   * @param {Object} event - Object containing the following properties:
   *   - header: The data of the header that triggered the event
   *
   * @returns {Object} A new copy of the state object with properties reflecting changes based on the event.
   */
  handleColumnSortingChange(state, event) {
    let updatedSortDirection;
    const updatedHeaders = state.headers.map((header) => {
      if (header.id !== event.header.id) {
        return header;
      }

      updatedSortDirection =
        header.sortDirection === "descending" ? "ascending" : "descending";
      return {
        ...header,
        sortDirection: updatedSortDirection,
      };
    });

    const propertyToSortBy = event.header.associatedDataProperty;
    const updatedData = [...state.data].sort((dataRow1, dataRow2) => {
      const resultBasedOnSortDirection =
        updatedSortDirection === "ascending" ? 1 : -1;

      let property1 = get(dataRow1, propertyToSortBy);
      let property2 = get(dataRow2, propertyToSortBy);

      const isProperty1AString = typeof property1 === "string";
      const isProperty2AString = typeof property2 === "string";

      // If the lowercase equivalents are equal, sort by their fully-cased values, i.e. "A" before "a"
      if (
        isProperty1AString &&
        isProperty2AString &&
        property1.toLowerCase() === property2.toLowerCase()
      ) {
        return defaultComparator(
          property1,
          property2,
          resultBasedOnSortDirection
        );
      }

      // If they are strings, convert them to lowercase
      if (typeof property1 === "string") {
        property1 = property1.toLowerCase();
      }

      if (typeof property2 === "string") {
        property2 = property2.toLowerCase();
      }

      return defaultComparator(
        property1,
        property2,
        resultBasedOnSortDirection
      );
    });

    return {
      ...state,
      headers: updatedHeaders,
      data: updatedData,
    };
  },
};

function defaultComparator(property1, property2, indexBasedOnSortDirection) {
  if (property1 === undefined) {
    return indexBasedOnSortDirection;
  }

  if (property2 === undefined) {
    return indexBasedOnSortDirection * -1;
  }

  if (property1 < property2) {
    return indexBasedOnSortDirection;
  } else if (property1 === property2) {
    return 0;
  } else {
    return indexBasedOnSortDirection * -1;
  }
}

export default DataTableStateReducer;
