import getWhiteLabelPrefix from "@/util/getWhiteLabelPrefix";

/**
 * Mixin for providing functionality for conditionally rendering components based on properties set in the
 * "Settings.json" file of each whitelabel implementation. If no "Settings.json" file is found, defaults to providing
 * the values found in "DefaultSettings.json".
 */
const WhiteLabelSettingsMixin = {
  provide() {
    const clientPrefix = getWhiteLabelPrefix();

    try {
      const WhiteLabelSettings = require("@/components/WhiteLabelProvider/clients/" +
        clientPrefix +
        "/Settings.json");

      return {
        WhiteLabelSettings,
      };
    } catch (err) {
      return {
        WhiteLabelSettings: require("./DefaultSettings.json"),
      };
    }
  },
};

export default WhiteLabelSettingsMixin;
