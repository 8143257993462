import WhiteLabelLoadingSkeleton from "@/components/WhiteLabelProvider/WhiteLabelLoadingSkeleton";
import DefaultWhiteLabelHandler from "@/components/WhiteLabelProvider/DefaultWhiteLabelHandler";

/**
 * Returns the white label handler for the client prefix specified.
 *
 * @param {String} clientPrefix - Unique string for the client to render.
 *
 * @returns {Object} An object representing an async Vue component.
 */
const getClientWhiteLabelComponent = (clientPrefix) => () => {
  // Using string concatenation rather than template literal because Jest does not know how to handle the dynamic import
  const getComponent = () =>
    import(
      "@/components/WhiteLabelProvider/clients/" + clientPrefix + "/index.js"
    );

  return {
    component: getComponent(),
    loading: WhiteLabelLoadingSkeleton,
    error: DefaultWhiteLabelHandler,
    delay: 500,
    timeout: 5000,
  };
};

export default getClientWhiteLabelComponent;
