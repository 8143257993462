import DataTable from "./src/DataTable";
import TableHeader from "./src/TableHeader";
import TableHeaderLabel from "./src/TableHeaderLabel";
import TableHeaderRow from "./src/TableHeaderRow";
import TableDataCell from "./src/TableDataCell";
import DataTableStateReducer from "./src/DataTableStateReducer";

/**
 * Utility function for importing the data table styles only when they are needed.
 */
function useDefaultDataTableStyle() {
  import("./src/styles.css");
}

export default DataTable;
export {
  TableHeader,
  TableHeaderLabel,
  TableHeaderRow,
  TableDataCell,
  DataTableStateReducer,
  useDefaultDataTableStyle,
};
