import { render, staticRenderFns } from "./MedicationDataTable.vue?vue&type=template&id=5cb58cbe&scoped=true"
import script from "./MedicationDataTable.vue?vue&type=script&lang=js"
export * from "./MedicationDataTable.vue?vue&type=script&lang=js"
import style0 from "./MedicationDataTable.vue?vue&type=style&index=0&id=5cb58cbe&prod&lang=css"
import style1 from "./MedicationDataTable.vue?vue&type=style&index=1&id=5cb58cbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb58cbe",
  null
  
)

export default component.exports