/**
 * Returns true if the provided formulary name is a Medicare formulary; false, otherwise.
 *
 * @returns {boolean} - True if the formulary is Medicare.
 */

function isBlueAdvantageFormulary(formularyName) {
  return formularyName === "BlueAdvantage";
}

function isBlueAdvantageExtraFormulary(formularyName) {
  return formularyName === "BlueAdvantage Extra";
}

function isBlueCarePlusFormulary(formularyName) {
  return formularyName === "BlueCare Plus";
}

function isMedicareFormulary(formularyName) {
  return isBlueAdvantageFormulary(formularyName) || isBlueAdvantageExtraFormulary(formularyName) || isBlueCarePlusFormulary(formularyName);
}

export { isBlueAdvantageFormulary, isBlueAdvantageExtraFormulary, isBlueCarePlusFormulary, isMedicareFormulary };
