<template>
  <div class="formulary-picker">
    <div style="min-width: 200px">
      <div class="mb-1 font-light formulary-type-heading">{{ $t("FormularyPicker.Your") }}</div>
      <div>
        <ListBox
          data-testid="formulary-select"
          labelled-by="Formulary Select"
          label="Drug Lists"
          @input="(value) => onFormularySelect(value)"
          :value="getSelectedFormularyValue()"
          :options="getAvailableFormularies()"
        >
          <template slot="trigger" slot-scope="{ onClick, isOpen, disabled, labelledBy }">
            <div
              :class="{
                'listbox-trigger-button-wrapper': isOpen,
                'listbox-trigger-button-wrapper-closed': !isOpen,
              }"
            >
              <button
                @click="onClick"
                ref="listboxTriggerButton"
                class="leading-normal text-left py-2 w-full rounded-sm items-center justify-between listbox-trigger-button"
                :class="{
                  'listbox-trigger-button-focus-outline': isOpen,
                  'listbox-trigger-button-disabled': disabled,
                }"
                aria-haspopup="listbox"
                :aria-expanded="isOpen"
                :aria-labelledby="labelledBy"
                :aria-disabled="disabled"
                data-testid="selected-formulary-button"
              >
                <div class="flex flex-row formulary-heading">
                  {{ getSelectedFormularyValue() }}
                  <Icon
                    name="chevron-down"
                    class="listbox-trigger-button-chevron ml-2"
                    :class="{
                      'listbox-trigger-button-chevron-disabled': disabled,
                    }"
                    v-if="!isOpen"
                  />
                  <Icon name="chevron-up" class="listbox-trigger-button-chevron ml-2" v-else />
                </div>
                <div>
                  <hr :class="{ 'hr-underline-is-hidden': isOpen }" />
                </div>
              </button>
            </div>
          </template>
        </ListBox>
      </div>
    </div>
  </div>
</template>

<script>
import ListBox from "@/components/ListBox/ListBox";
import Icon from "@/components/Icon";
import { isMedicareFormulary } from "@/util/isMedicareFormulary";

export default {
  name: "FormularyPicker",
  components: { ListBox, Icon },
  props: {
    /**
     * List of formulary objects for the formularies to pick from. These objects should have the follow properties:
     * - id
     * - name
     */
    formularies: {
      type: Array,
      required: true,
    },
    selectedFormularyIndex: {
      type: Number,
      default: null,
    },
    selectedFormularyTypeIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      indexOfFormularySelected: undefined,
    };
  },
  mounted() {
    this.getAvailableFormularies();
  },
  methods: {
    getAvailableFormularies() {
      var formularyDescriptions = this.formularies.map((formulary) => formulary.productScheduleDescription);
      if (this.selectedFormularyTypeIndex === 1) {
        return formularyDescriptions.filter((formularyName) => isMedicareFormulary(formularyName));
      } else {
        return formularyDescriptions.filter((formularyName) => !isMedicareFormulary(formularyName));
      }
    },
    getSelectedFormularyValue() {
      return this.selectedFormularyIndex != null ? this.formularies[this.selectedFormularyIndex].productScheduleDescription : "Select";
    },
    onFormularySelect(value) {
      const index = this.formularies.findIndex((formulary) => formulary.productScheduleDescription === value);

      this.indexOfFormularySelected = index;
      this.$emit("formulary-selected", { index });
    },
  },
};
</script>

<style scoped>
.formulary-picker {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.formulary-type-heading {
  color: var(--LandingPage-SearchContainer-Description-TextColor);
}

.listbox-trigger-button {
  width: auto;
  color: var(--BaseButton-Color);
  background: none;
  font-weight: 300;
}

.listbox-trigger-button-wrapper {
  color: var(--BaseButton-Color);
  font-weight: 300;
  box-shadow: none;
}

.listbox-trigger-button-wrapper-closed {
  color: var(--BaseButton-Color);
  font-weight: 300;
}

.listbox-trigger-button-chevron {
  color: var(--BaseButton-Color);
  font-size: 16px;
  font-weight: 300;
  margin-top: auto;
  margin-bottom: auto;
}

.formulary-heading {
  font-size: 1.5rem;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: 300;
  font-style: normal;
}

hr {
  background-color: var(--BaseButton-Color);
  border: 0 none;
  height: 1px;
}

.hr-underline-is-hidden {
  background-color: unset;
}

.listbox-trigger-button-wrapper-closed:hover hr {
  visibility: hidden;
}

.listbox-trigger-button:focus hr {
  visibility: hidden;
}

.listbox-trigger-button:focus {
  outline: 0;
  box-shadow: none;
}

.listbox-trigger-button-focus-outline {
  box-shadow: none;
}
</style>

<style>
.formulary-picker .listbox-selected-option {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: bold;
}

.formulary-picker .listbox-list-container {
  padding-top: 0px;
}

.formulary-picker .listbox-option {
  font-size: 1rem;
}

.formulary-picker .listbox-active-option {
  background-color: #f5f5f5;
}
</style>
