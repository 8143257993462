<template>
  <div>
    <slot :name="formFactor"></slot>
  </div>
</template>

<script>
import { detect } from "detect-browser";
import VueScreenSize from "vue-screen-size";

/**
 * Component that provides various slots that are conditionally rendered based on the screen size. Provides the
 * following slots:
 *
 * - "desktop" - When the screen width is greater than 1000px.
 * - "mobile" - The default when any above criteria has not been met.
 *
 */
export default {
  name: "ScreenSizeManager",
  mixins: [VueScreenSize.VueScreenSizeMixin],
  computed: {
    formFactor() {
      const browser = detect();

      // TODO: Remove. There are various experiences that do not behave well on IE 11 for desktop. This is a temporary fix.
      if (browser && browser.name === "ie") {
        return "mobile";
      }

      if (this.$vssWidth >= 1000) {
        return "desktop";
      }

      return "mobile";
    },
  },
};
</script>

<style scoped></style>
