<template>
  <div v-if="tier">
    <div class="leading-6">{{ $t("TierTag.Message") }} {{ tier }}</div>
  </div>
</template>

<script>
export default {
  name: "DrugTier",
  props: {
    coverageInfo: {
      type: Object,
    },
  },
  computed: {
    tier() {
      return this.coverageInfo?.coverageTier?.tier;
    },
  },
};
</script>
