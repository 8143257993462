<template>
  <table class="DataTable-Table" role="grid">
    <TableHeaderRow>
      <template v-for="header in headers">
        <TableHeader
          :header="header"
          :key="header.id"
          @column-sort-toggle="onColumnSortToggle"
          :is-this-header-actively-sorted="isHeaderActivelySorted(header)"
        >
          <template slot-scope="slotProps">
            <slot
              :name="getHeaderContentSlotName(header)"
              :header="header"
              :onColumnSortClick="slotProps.onColumnSortClick"
            />
          </template>
        </TableHeader>
      </template>
    </TableHeaderRow>
    <tbody style="min-height: 500px;">
      <EmptyRow
        v-if="!isLoading && (!data || data.length === 0)"
        :number-of-columns="headers.length"
      >
        <div
          class="DataTable-NoContentPlaceholder"
          data-testid="DataTable-NoContentPlaceholder"
        >
          <template>
            <slot name="no-content-placeholder"></slot>
          </template>
        </div>
      </EmptyRow>
      <template v-if="data && data.length > 0">
        <template v-for="datum in data">
          <TableDataRow :key="datum.id">
            <slot name="data-table-row" :rowData="datum">
              <template v-for="header in headers">
                <slot
                  :name="`cell-renderer-${header.associatedDataProperty}`"
                  :rowData="datum"
                >
                  <TableDataCell :key="header.id">
                    {{ getFormattedProperty(datum, header) }}
                  </TableDataCell>
                </slot>
              </template>
            </slot>
          </TableDataRow>
        </template>
      </template>
      <EmptyRow v-if="isLoading" :number-of-columns="headers.length">
        <div class="DataTable-LoadingIndicatorPlaceholder">
          <slot name="loading-indicator-placeholder"></slot>
        </div>
      </EmptyRow>
    </tbody>
  </table>
</template>

<script>
import get from "lodash.get";
import TableHeader from "@/components/DataTable/src/TableHeader";
import TableDataCell from "@/components/DataTable/src/TableDataCell";
import TableDataRow from "@/components/DataTable/src/TableDataRow";
import TableHeaderRow from "@/components/DataTable/src/TableHeaderRow";
import EmptyRow from "@/components/DataTable/src/EmptyRow";

/**
 * A generic data table component. In general, this component should be designed such that domain-specific use cases
 * are customized through external configuration.
 */
export default {
  name: "DataTable",
  components: {
    EmptyRow,
    TableHeaderRow,
    TableDataRow,
    TableDataCell,
    TableHeader,
  },
  props: {
    /**
     * Objects matching the {@link HeaderConfiguration} interface.
     */
    headers: {
      type: Array,
      required: true,
    },

    /**
     * Array of objects. Each object should have an "id" property attached to it.
     */
    data: {
      type: Array,
      required: true,
      validator: (value) => {
        const isValid = value.every(
          (entry) => entry.id !== undefined || entry.id !== null
        );

        if (!isValid) {
          // eslint-disable-next-line no-console
          console.error(
            'An entry in the data provided to the DataTable component is missing an "id" property.'
          );
        }

        return isValid;
      },
    },

    /**
     * Whether or not the data for the data table is loading.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },

    /**
     * The message to display next to the loading indicator.
     */
    loadingMessage: {
      type: String,
      default: "Loading",
    },
  },
  data() {
    return {
      idOfActivelySortedHeader: null,
    };
  },
  methods: {
    onColumnSortToggle(eventData) {
      this.$emit("column-sort-toggle", eventData);
      this.idOfActivelySortedHeader = eventData.header.id;
    },
    getHeaderContentSlotName(header) {
      return `table-header-renderer-id-${header.id}`;
    },
    isHeaderActivelySorted(header) {
      return this.idOfActivelySortedHeader === header.id;
    },
    getFormattedProperty(rowData, header) {
      const value = get(rowData, header.associatedDataProperty);

      return header.customDataFormatting
        ? header.customDataFormatting(value)
        : value;
    },
  },
};
</script>
