<script lang="jsx">
import Icon from "@/components/Icon";

export default {
  name: "TableHeader",
  render() {
    const Header = (dataObject) => {
      const { children } = dataObject;

      return (
        <th
          ariaSort={this.ariaSort}
          id={`DataTable-TableHeader-${this.header.id}`}
          class="DataTable-TableHeader"
        >
          {this.$scopedSlots.default({
            onColumnSortClick: this.onColumnSortClick,
            header: this.header,
          }) || children}
        </th>
      );
    };

    const buttonAttributes = {
      "data-testid": "sort-icon-" + this.header.id,
    };

    return (
      <Header>
        {!this.header.isSortable ? (
          <span class="DataTable-TableHeader-label">{this.header.text}</span>
        ) : (
          <div class="DataTable-TableHeader-sortbutton">
            <span class="DataTable-TableHeader-label">{this.header.text}</span>
            <button vOn:click={this.onColumnSortClick} attrs={buttonAttributes}>
              <Icon name="sort" />
            </button>
          </div>
        )}
      </Header>
    );
  },
  props: {
    header: {
      type: Object,
    },
    isThisHeaderActivelySorted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Whether or not this column has undergone a sorting. Used to set the aria attribute, as we assume the data on
       * initial render will come unsorted.
       */
      hasSortingBeenRequested: false,
    };
  },
  methods: {
    onColumnSortClick() {
      this.$emit("column-sort-toggle", { header: this.header });
      this.hasSortingBeenRequested = true;
    },
  },
  computed: {
    ariaSort() {
      if (!this.hasSortingBeenRequested || !this.isThisHeaderActivelySorted) {
        return "none";
      }
      return this.header.sortDirection;
    },
  },
};
</script>

<style scoped></style>
