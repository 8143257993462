<template>
  <div id="app">
    <StateMachineProvider>
      <ToastManager>
        <WhiteLabelProvider :client-prefix="clientPrefix">
          <router-view />
        </WhiteLabelProvider>
      </ToastManager>
    </StateMachineProvider>
    <ScrollToTop />
  </div>
</template>

<script>
import WhiteLabelProvider from "@/components/WhiteLabelProvider/WhiteLabelProvider";
import getWhiteLabelPrefix from "@/util/getWhiteLabelPrefix";
import StateMachineProvider from "@/components/StateMachineProvider/StateMachineProvider";
import ToastManager from "@/components/ToastManager";
import { mapActions } from "vuex";
import ScrollToTop from "@/components/ScrollToTop";

export default {
  components: {
    StateMachineProvider,
    WhiteLabelProvider,
    ToastManager,
    ScrollToTop,
  },
  methods: {
    ...mapActions(["makeRequestToGetFormularies", "setDefaultFormularyName"]),
  },
  computed: {
    clientPrefix() {
      return getWhiteLabelPrefix();
    },
  },
  created() {
    // FORM-272: Removing tutorial functionality temporarily based on usability testing results. Can remove code once
    // final decision has been made.
    // if (TutorialRedirectUtil.shouldUserRedirectToTutorial()) {
    //   this.$router.replace({ name: "Tutorial" });
    // }
  },
  mounted() {
    let name = this.$router.currentRoute.query.defaultFormulary;

    // Use Rx Preferred as default formulary until Medicare formularies are included
    name = name ? name : "Rx Preferred";

    this.setDefaultFormularyName({ name });

    this.makeRequestToGetFormularies();
  },
};
</script>
