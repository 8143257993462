import uniqWith from "lodash.uniqwith";

/**
 * This removes duplicate doses for a particular medication for a particular formulary.
 * The duplicate is based on the "form" and "dose". This is a temporary fix; A unique product
 * is defined by a form and a strength, which means we should not be seeing duplicate dosages.
 * However, because we use the medispan database, we are not getting unique strength names.
 * This can be removed once the data returns unique only unique entries for dose.
 */
export default function removeDuplicateDoses(medicationCoverageResponse) {
  medicationCoverageResponse.coverageInfo.forEach((coverageInfo) => {
    const medispanDrugDescriptorIds = coverageInfo.coverages.map(
      (coverage) => coverage.medispanDrugDescriptorId
    );

    const medicationDetails = medicationCoverageResponse.medicationDetails.filter(
      (medicationDetail) =>
        medispanDrugDescriptorIds.includes(
          medicationDetail.medispanDrugDescriptorId
        )
    );

    if (medicationDetails.length === 1) {
      return;
    }

    const uniqueMedicationDetails = uniqWith(
      medicationDetails,
      isMedicationDetailEquivalent
    );

    const idsToKeep = uniqueMedicationDetails.map(
      (detail) => detail.medispanDrugDescriptorId
    );
    coverageInfo.coverages = coverageInfo.coverages.filter((coverage) =>
      idsToKeep.includes(coverage.medispanDrugDescriptorId)
    );
  });

  return medicationCoverageResponse;
}

function isMedicationDetailEquivalent(medicationDetailA, medicationDetailB) {
  return (
    medicationDetailA.form === medicationDetailB.form &&
    medicationDetailA.dose === medicationDetailB.dose
  );
}
