<template>
  <div class="flex justify-center" data-testid="loading-indicator">
    <div>
      <img :src="loadingIcon" alt="Loading Icon" class="loading-icon" />
    </div>
    <div class="ml-2 my-auto text-sm leading-6 loading-message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import LoadingIcon from "@/assets/loader.svg";

export default {
  name: "Loader",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  computed: {
    loadingIcon() {
      return LoadingIcon;
    },
  },
};
</script>

<style>
.loading-icon {
  width: 34px;

  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading-message {
  color: var(--BaseLoader-LoadingMessage-Color);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
