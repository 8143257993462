<template>
  <td class="DataTable-TableDataCell">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TableDataCell",
};
</script>
