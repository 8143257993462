<template>
  <li
    ref="listBoxOption"
    v-bind="$attrs"
    v-on="$listeners"
    class="w-full px-4 py-2 mb-1 rounded text-sm leading-normal listbox-option"
    :class="{
      'listbox-selected-option': selected,
      'listbox-active-option': active,
    }"
    role="option"
    tabindex="-1"
    :aria-selected="selected"
  >
    {{ option }}
  </li>
</template>

<script>
export default {
  name: "ListBoxOption",
  props: {
    option: {
      type: [String, Number],
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.$refs.listBoxOption.focus();
      }
    },
  },
  mounted() {
    if (this.selected && this.active) {
      this.$refs.listBoxOption.focus();
    }
  },
};
</script>

<style scoped>
.listbox-option {
  cursor: pointer;
  text-align: left;
  scroll-snap-align: start;
}

.listbox-active-option {
  background-color: #b3eeff;
  outline: 0;
}

.listbox-selected-option {
  font-family: Roboto-Bold, sans-serif;
  font-weight: bold;
}
</style>
