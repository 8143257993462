<template>
  <div data-testid="coverage-list" v-if="isLoadingCoverageListMedication || coverageListMedications.length !== 0" ref="coverageListManager">
    <div v-if="!showMultiPlanYear"><CoverageInformationDisclaimer /></div>
    <ScreenSizeManager>
      <template slot="mobile">
        <MedicationCard
          v-for="medication in medications"
          :key="medication.id"
          :medication="medication"
          :selected-form-index="selectedFormIndexes[medication.id]"
          :selected-dose-index="selectedDoseIndexes[medication.id]"
          :selected-formulary-id="formulariesByPlanYear[selectedFormularyIndex].productScheduleId"
          :forms-and-doses="formsAndDoses"
          @form-select="handleFormSelect"
          @dose-select="handleDoseSelect"
          :is-loading="isLoadingCoverageListMedication"
          class="mb-4"
          ref="medicationCards"
        />
        <LoadingCard v-if="isLoadingCoverageListMedication" :message="$t('CoverageListManager.LoadingMessage')" />
      </template>
      <template slot="desktop">
        <MedicationDataTable
          :medications="medications"
          :selected-form-indexes="selectedFormIndexes"
          :selected-formulary-id="formulariesByPlanYear[selectedFormularyIndex].productScheduleId"
          :selected-dose-indexes="selectedDoseIndexes"
          :forms-and-doses="formsAndDoses"
          @form-select="handleFormSelect"
          @dose-select="handleDoseSelect"
          :is-loading="isLoadingCoverageListMedication"
          ref="medicationDataTable"
        />
      </template>
    </ScreenSizeManager>
    <!-- Download as CSV button -->
    <div class="flex flex-row mt-4 justify-between">
      <div class="flex flex-column items-end download-coverage-list-section ml-auto" v-if="!StateMachine.current.matches('tutorial') && medications.length > 0">
        <button
          @click="handleDownloadCoverageListClick"
          data-testid="download-coverage-list-button"
          class="mb-4"
          :aria-label="$t('CoverageListManager.DownloadCoverageListButtonAriaLabel')"
        >
          <Icon type="fal" name="arrow-to-bottom" class="download-coverage-list-icon mr-2 md:inline" />
          <span class="font-bold md:inline">{{ $t("CoverageListManager.DownloadCoverageListButton") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ScreenSizeManager from "@/components/ScreenSizeManager";
import MedicationCard from "@/components/MedicationCard";
import MedicationDataTable from "@/components/MedicationDataTable/MedicationDataTable";
import Icon from "@/components/Icon";
import LoadingCard from "@/components/LoadingCard";
import { planYear, isMultiYearEnabled } from "@/util/getMultiPlanYearData";
import CoverageInformationDisclaimer from "@/components/CoverageInformationDisclaimer";
import { detect } from "detect-browser";

/**
 * Component that handles showing the Medication Card or Medication Table.
 **/
export default {
  name: "CoverageListManager",
  inject: ["StateMachine"],
  components: {
    CoverageInformationDisclaimer,
    LoadingCard,
    MedicationDataTable,
    ScreenSizeManager,
    MedicationCard,
    Icon,
  },
  props: {
    medications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      planYearFilterOptions: [
        {
          value: planYear.THIS_YEAR == Math.min(planYear.THIS_YEAR, planYear.MULTI_YEAR),
          displayValue: this.$t("CoverageListManager.PlanYearFilterOptions.PlanYear", [Math.min(planYear.THIS_YEAR, planYear.MULTI_YEAR)]),
        },
        {
          value: planYear.THIS_YEAR == Math.max(planYear.THIS_YEAR, planYear.MULTI_YEAR),
          displayValue: this.$t("CoverageListManager.PlanYearFilterOptions.PlanYear", [Math.max(planYear.THIS_YEAR, planYear.MULTI_YEAR)]),
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "selectedFormIndexes",
      "selectedDoseIndexes",
      "selectedFormularyIndex",
      "coverageListMedications",
      "isLoadingCoverageListMedication",
      "formularies",
      "formsAndDoses",
      "scrollToIndex",
      "isThisPlanYear",
    ]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    showMultiPlanYear() {
      return isMultiYearEnabled();
    },
    formulariesByPlanYear() {
      return this.getFormulariesByPlanYear();
    },
    isUsingInternetExplorer() {
      const browser = detect();

      return browser && browser.name === "ie";
    },
  },
  methods: {
    ...mapActions(["setSelectedPlanYear"]),
    /**
     * Public method for consumer to scroll to a new entry in the list.
     */
    scrollToNewEntry() {
      if (this.$refs.medicationCards && this.$refs.medicationCards.length > 0) {
        const indexToScrollTo = this.scrollToIndex !== undefined ? this.scrollToIndex : this.$refs.medicationCards.length - 1;

        if (indexToScrollTo) {
          // If indexToScrollTo is set, scroll to the corresponding index
          if (this.isUsingInternetExplorer) {
            this.$refs.medicationCards[indexToScrollTo].$el.scrollIntoView(false);
          } else {
            this.$refs.medicationCards[indexToScrollTo].$el.scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          }
        } else {
          // If the indexToScrollTo is set to 0 or not set, scroll to the first item
          if (this.isUsingInternetExplorer) {
            window.scrollTo(0, 440);
          } else {
            window.scrollTo({ top: 640, behavior: "smooth" });
          }
        }
      } else if (this.$refs.medicationDataTable) {
        // Find the last row in the table, then scroll to that row
        const tableRows = this.$refs.medicationDataTable.$el.querySelector("tbody").querySelectorAll(".DataTable-TableDataRow");
        const rowIndex = tableRows.length - 1;

        tableRows[rowIndex].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
      this.$store.commit("setScrollToIndex", undefined);
    },
    ...mapActions(["setSelectedFormIndex", "setSelectedDoseIndex"]),
    handleFormSelect(eventPayload) {
      const { medication, selectedFormIndex } = eventPayload;
      this.setSelectedFormIndex({ medication, selectedFormIndex });
    },
    handleDoseSelect(eventPayload) {
      const { medication, selectedDoseIndex } = eventPayload;
      this.setSelectedDoseIndex({ medication, selectedDoseIndex });
    },
    handleDownloadCoverageListClick() {
      import("@/util/convertCoverageListToCSV").then(({ default: convertCoverageListToCSV }) => {
        const csv = convertCoverageListToCSV({
          coverageListMedications: this.coverageListMedications,
          selectedFormIndexes: this.selectedFormIndexes,
          selectedDoseIndexes: this.selectedDoseIndexes,
          selectedFormulary: this.formulariesByPlanYear[this.selectedFormularyIndex],
          formsAndDoses: this.formsAndDoses,
        });
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

        import("file-saver").then(({ default: saveAs }) => {
          saveAs(blob, "drug-list.csv");
        });
      });
    },
    handlePlanYearSelection: function (isThisPlanYear) {
      this.setSelectedPlanYear({ isThisPlanYear });
    },
  },
};
</script>

<style scoped>
.coverage-list-header {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.download-coverage-list-section {
  font-family: Roboto-Bold, sans-serif;
  color: var(--BaseLink-Color);
  white-space: nowrap;
}

.download-coverage-list-icon {
  width: 12px;
  height: 16px;
}
</style>
