<template>
  <div class="p-6 w-full flex items-center justify-center loading-card">
    <Loader :message="message" />
  </div>
</template>

<script>
import Loader from "@/components/Loader";

/**
 * Generic responsive card that shows a loading indicator and a custom message.
 */
export default {
  name: "LoadingCard",
  components: { Loader },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.loading-card {
  height: 184px;
  border-radius: 8px;
  border: 1px solid var(--BaseCard-BorderColor);
}
</style>
