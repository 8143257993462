<template>
  <div>
    <div
      v-for="(coverage, index) in coverageInfo"
      :key="`coverage-alert-${index}`"
      class="coverage-alert-link pb-4"
    >
      <Popover>
        <template slot="tooltip-trigger">
          <button class="text-left flex text-sm">
            <div>
              <Icon
                :name="notCoveredIcon(coverage.durServiceDescription)"
                size="lg"
                class="pr-1"
              />
            </div>
            <div class="coverage-text break-words">
              {{ coverage.durServiceDescription }}
            </div>
          </button>
        </template>
        <template slot="tooltip">
          <span v-html="coverage.message" />
        </template>
      </Popover>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import Popover from "@/components/Popover/Popover";

export default {
  name: "CoverageAlert",
  components: {
    Icon,
    Popover,
  },
  props: {
    coverageInfo: {
      type: Array,
      default() {
        // If there are really no coverage alerts, this prop will be passed an empty array. This default handles if
        // the entire coverage info entry is undefined.
        return [
          {
            durServiceDescription: this.$t(
              "CoverageAlert.NotCoveredCoverageAlertName"
            ),
            message: this.$t(
              "CoverageAlert.NotCoveredCoverageAlertDescription"
            ),
          },
        ];
      },
    },
  },
  methods: {
    notCoveredIcon(name) {
      return name === "Not Covered" ? "ban" : "exclamation-triangle";
    },
  },
};
</script>

<style>
.coverage-alert-link {
  color: var(--BaseLink-Color);
}
.coverage-text {
  margin-top: 0.15rem;
}
</style>
