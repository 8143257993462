<template>
  <transition name="slide-fade">
    <div
      data-testid="onboarding-progress-bar"
      class="w-full flex flex-row items-center progress-bar px-6 fixed bg-white"
      v-if="this.StateMachine.current.matches('tutorial.immersiveTutorial')"
    >
      <div class="flex flex-col lg:flex-row w-full">
        <div class="flex flew-row items-center mb-4 lg:mb-0">
          <div
            class="tutorial-status-pill"
            :class="{
              'active-tutorial-status-pill': StateMachine.current.matches(
                'tutorial.immersiveTutorial.search'
              ),
            }"
          >
            <span>{{ $t("OnboardingProgressBar.Steps.First.StepCount") }}</span>
            <span>{{
              $t("OnboardingProgressBar.Steps.First.ActiveLabel")
            }}</span>
          </div>
          <span class="spacer" />
          <div
            class="tutorial-status-pill"
            :class="{
              'active-tutorial-status-pill': StateMachine.current.matches(
                'tutorial.immersiveTutorial.formularyComparison.untrained'
              ),
            }"
          >
            <span>{{
              $t("OnboardingProgressBar.Steps.Second.StepCount")
            }}</span>
            <span>{{
              $t("OnboardingProgressBar.Steps.Second.ActiveLabel")
            }}</span>
          </div>
          <span class="spacer" />
          <div
            class="tutorial-status-pill"
            :class="{
              'active-tutorial-status-pill': StateMachine.current.matches(
                'tutorial.immersiveTutorial.formularyComparison.trained'
              ),
            }"
          >
            <span>{{ $t("OnboardingProgressBar.Steps.Third.StepCount") }}</span>
            <span>{{
              $t("OnboardingProgressBar.Steps.Third.ActiveLabel")
            }}</span>
          </div>
        </div>
        <div
          v-if="
            !this.StateMachine.current.matches(
              'tutorial.immersiveTutorial.formularyComparison.trained'
            )
          "
          class="flex flex-row justify-end"
        >
          <button
            class="text-sm font-normal leading-6 h-full ml-6 skip-tutorial-button"
            @click="handleCloseTutorialClick"
          >
            {{ $t("OnboardingProgressBar.SkipTutorial") }}
          </button>
        </div>
        <div
          v-if="
            this.StateMachine.current.matches(
              'tutorial.immersiveTutorial.formularyComparison.trained'
            )
          "
          class="ml-4 flex flex-col lg:flex-row lg:items-center w-full"
        >
          <span
            class="mr-4 italic text-sm font-light leading-6 mb-3 lg:mb-0 tutorial-success-message"
            >{{
              $t("OnboardingProgressBar.TutorialCompleteCongratulations")
            }}</span
          >
          <button
            class="rounded-full px-4 py-1 leading-6 h-full whitespace-no-wrap close-tutorial-button"
            @click="handleCloseTutorialClick"
          >
            {{ $t("OnboardingProgressBar.CloseTutorialButton") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { NotificationTypes } from "@/util/NotificationTypes";

/**
 * Component for showing the user the progress they have made through the tutorial.
 */
export default {
  name: "OnboardingProgressBar",
  inject: ["StateMachine"],
  methods: {
    handleCloseTutorialClick() {
      const tutorialCompleteNotification = {
        type: NotificationTypes.TUTORIAL_COMPLETE,
      };

      this.$router.push({
        name: "Home",
        params: { notifications: [tutorialCompleteNotification] },
      });
    },
  },
};
</script>

<style scoped>
.progress-bar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  min-height: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 100;
}

.tutorial-success-message {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  color: rgb(0, 73, 126);
}

.tutorial-status-pill {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 94, 185);
  border-radius: 9999px;
  font-weight: 500;
  line-height: 24px;
  padding: 4px 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.tutorial-status-pill > span:nth-child(2) {
  display: none;
}

.active-tutorial-status-pill {
  background-color: rgb(0, 73, 126);
  color: rgb(255, 255, 255);
  width: auto;
  height: 30px;
  padding: 4px 16px;
}

.active-tutorial-status-pill > span:nth-child(1) {
  display: none;
}

.active-tutorial-status-pill > span:nth-child(2) {
  display: inline;
}

.spacer {
  margin-left: 12px;
  margin-right: 12px;
  content: "";
  width: 100px;
  min-width: 10px;
  border-top: 2px solid rgb(0, 73, 126);
}

.close-tutorial-button {
  background-color: rgb(0, 94, 185);
  color: #fff;
}

.skip-tutorial-button {
  font-family: Roboto-Regular, sans-serif;
  color: var(--BaseLink-Color);
  text-decoration: underline;
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}

@media screen and (min-width: 800px) {
  .spacer {
    width: 66px;
  }
}
</style>
