import axios from "axios";

let adjudifyAxios;

export default function setupAdjudifyAxios(environment = "production") {
  if (
    environment === "development" ||
    environment === "test" ||
    (window.Cypress && window.location.href.includes("localhost")) // Only added if running Cypress test
  ) {
    adjudifyAxios = axios.create({
      baseURL: "/api",
    });
  } else {
    adjudifyAxios = axios.create({
      baseURL: process.env.VUE_APP_ADJUDIFY_API_URL,
    });
  }
}

export { adjudifyAxios };
