var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade"}},[(this.StateMachine.current.matches('tutorial.immersiveTutorial'))?_c('div',{staticClass:"w-full flex flex-row items-center progress-bar px-6 fixed bg-white",attrs:{"data-testid":"onboarding-progress-bar"}},[_c('div',{staticClass:"flex flex-col lg:flex-row w-full"},[_c('div',{staticClass:"flex flew-row items-center mb-4 lg:mb-0"},[_c('div',{staticClass:"tutorial-status-pill",class:{
            'active-tutorial-status-pill': _vm.StateMachine.current.matches(
              'tutorial.immersiveTutorial.search'
            ),
          }},[_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.First.StepCount")))]),_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.First.ActiveLabel")))])]),_c('span',{staticClass:"spacer"}),_c('div',{staticClass:"tutorial-status-pill",class:{
            'active-tutorial-status-pill': _vm.StateMachine.current.matches(
              'tutorial.immersiveTutorial.formularyComparison.untrained'
            ),
          }},[_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.Second.StepCount")))]),_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.Second.ActiveLabel")))])]),_c('span',{staticClass:"spacer"}),_c('div',{staticClass:"tutorial-status-pill",class:{
            'active-tutorial-status-pill': _vm.StateMachine.current.matches(
              'tutorial.immersiveTutorial.formularyComparison.trained'
            ),
          }},[_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.Third.StepCount")))]),_c('span',[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.Steps.Third.ActiveLabel")))])])]),(
          !this.StateMachine.current.matches(
            'tutorial.immersiveTutorial.formularyComparison.trained'
          )
        )?_c('div',{staticClass:"flex flex-row justify-end"},[_c('button',{staticClass:"text-sm font-normal leading-6 h-full ml-6 skip-tutorial-button",on:{"click":_vm.handleCloseTutorialClick}},[_vm._v(" "+_vm._s(_vm.$t("OnboardingProgressBar.SkipTutorial"))+" ")])]):_vm._e(),(
          this.StateMachine.current.matches(
            'tutorial.immersiveTutorial.formularyComparison.trained'
          )
        )?_c('div',{staticClass:"ml-4 flex flex-col lg:flex-row lg:items-center w-full"},[_c('span',{staticClass:"mr-4 italic text-sm font-light leading-6 mb-3 lg:mb-0 tutorial-success-message"},[_vm._v(_vm._s(_vm.$t("OnboardingProgressBar.TutorialCompleteCongratulations")))]),_c('button',{staticClass:"rounded-full px-4 py-1 leading-6 h-full whitespace-no-wrap close-tutorial-button",on:{"click":_vm.handleCloseTutorialClick}},[_vm._v(" "+_vm._s(_vm.$t("OnboardingProgressBar.CloseTutorialButton"))+" ")])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }