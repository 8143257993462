<template>
  <div>
    <div
      class="fixed bottom-0 flex flex-col justify-center items-center w-full z-50 toasts-container"
    >
      <transition-group name="fade">
        <Toast
          :id="toast.id"
          :title="toast.title"
          :message="toast.message"
          :variant="toast.variant"
          :include-dismiss-button="toast.includeDismissButton"
          @on-toast-dismiss="dismissToast"
          class="individual-toast"
          v-for="toast in activeToasts"
          :key="toast.id"
          data-testid="individual-toast"
        />
      </transition-group>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Toast from "./Toast";

/**
 * Renders a list of Toasts.
 */
export default {
  name: "ToastManager",
  components: {
    Toast,
  },
  computed: {
    ...mapState("Toasts", ["activeToasts"]),
  },
  methods: {
    ...mapActions("Toasts", ["dismissToast"]),
  },
};
</script>

<style scoped>
.toasts-container {
  padding-bottom: 8px;
}

.individual-toast {
  margin-bottom: 8px;
}

@media screen and (min-width: 800px) {
  .toasts-container {
    padding-left: 24px;
    align-items: flex-start;
    padding-bottom: 0;
  }

  .individual-toast {
    max-width: 400px;
  }
}

.fade-enter-active {
  animation: fade-in 0.5s;
}

.fade-leave-active {
  animation: fade-in 0.5s reverse;
}

@keyframes fade-in {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
