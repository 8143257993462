<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { interpret } from "xstate";
import StateMachine from "./StateMachine";

/**
 * Component for making the application's state machine available to all components underneath this provider.
 */
export default {
  name: "StateMachineProvider",
  methods: {
    send(event) {
      this.Service.send(event);
    },
  },
  data() {
    return {
      Service: interpret(StateMachine),
      StateMachine: {
        current: StateMachine.initialState,
        context: StateMachine.context,
        send: (event) => {
          this.send(event);
        },
      },
    };
  },
  provide() {
    return {
      StateMachine: this.StateMachine,
    };
  },
  created() {
    this.Service.onTransition((state) => {
      this.StateMachine.current = state;
      this.StateMachine.context = state.context;
    }).start();
  },
};
</script>
