<template>
  <div class="page-layout">
    <OnboardingProgressBar />
    <div v-if="isHeaderVisible">
      <slot name="header"></slot>
    </div>
    <main class="page-content">
      <slot name="content"></slot>
    </main>
    <slot name="footer"></slot>
  </div>
</template>

<script>
/**
 * Component that handles placing the header and footer in the correct spots.
 */
import OnboardingProgressBar from "@/components/OnboardingProgressBar";

export default {
  name: "DefaultPageLayout",
  inject: ["StateMachine"],
  components: { OnboardingProgressBar },
  computed: {
    isHeaderVisible() {
      return this.StateMachine.current.matches("initial") || this.StateMachine.current.matches("tutorial.initial");
    },
  },
};
</script>

<style scoped>
.page-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.page-content {
  flex: 1 0 auto;
}
</style>
