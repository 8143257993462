<template>
  <div class="medicare-price-data-table text-left">
    <div class="font-bold text-lg text-center leading-6 heading">
      {{ $t("MedicareBlueAdvantageExtraPriceDataTable.StandardRetailAndMailOrderPharmacy") }}
      <p class="header-small">{{ $t("MedicareBlueAdvantageExtraPriceDataTable.30DaysSupply") }}</p>
    </div>
    <DataTable :headers="headers" :data="data" data-testid="medicare-price-data-table">
      <template slot="table-header-renderer-id-1" slot-scope="{ header }">
        <TableHeaderLabel>
          <div class="text-center leading-6">{{ header.text }}</div>
        </TableHeaderLabel>
      </template>
      <template slot="table-header-renderer-id-2" slot-scope="{ header }">
        <TableHeaderLabel>
          <TableHeaderLabel>
            <div class="text-center leading-6">{{ header.text }}</div>
          </TableHeaderLabel>
        </TableHeaderLabel>
      </template>
      <template slot="cell-renderer-planName" slot-scope="{ rowData }">
        <TableDataCell class="font-bold leading-6">
          {{ rowData.planName }}
        </TableDataCell>
      </template>
      <template slot="cell-renderer-generic" slot-scope="{ rowData }">
        <TableDataCell class="leading-6">
          <b>{{ rowData.generic }}</b> copay
        </TableDataCell>
      </template>
      <template slot="cell-renderer-brand" slot-scope="{ rowData }">
        <TableDataCell class="leading-6">
          <b>{{ rowData.brand }}</b> copay
        </TableDataCell>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable, { TableHeaderLabel, useDefaultDataTableStyle } from "@/components/DataTable";
import TableDataCell from "@/components/DataTable/src/TableDataCell";

useDefaultDataTableStyle();

export default {
  name: "MedicareBlueAdvantageExtraPriceDataTable",
  components: {
    TableDataCell,
    TableHeaderLabel,
    DataTable,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          id: 0,
          text: "",
          associatedDataProperty: "planName",
          isSortable: false,
        },
        {
          id: 1,
          text: this.$t("MedicareBlueAdvantageExtraPriceDataTable.GenericTier"),
          associatedDataProperty: "generic",
          isSortable: false,
        },
        {
          id: 2,
          text: this.$t("MedicareBlueAdvantageExtraPriceDataTable.BrandTier"),
          associatedDataProperty: "brand",
          isSortable: false,
        },
      ],
      data: [
        {
          planName: this.$t("MedicareBlueAdvantageExtraPriceDataTable.StandardCoinsurance"),
          generic: "25%",
          brand: "25%",
        },
        {
          planName: this.$t("MedicareBlueAdvantageExtraPriceDataTable.WithExtraHelp"),
          generic: "$0-$4.50",
          brand: "$0-$11.20",
        },
      ],
    };
  },
};
</script>

<style scoped>
.medicare-price-data-table {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.header-small {
  font-family: Roboto-Regular, sans-serif;
}

.heading {
  background-color: var(--LandingPage-CoverageInformationDisclaimer-BackgroundColor);
  color: var(--LandingPage-CoverageInformationDisclaimer-Color);
  padding: 16px;
}
</style>
