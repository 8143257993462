<template>
  <DataTable :headers="headers" :data="visibleMedications" @column-sort-toggle="onColumnSortToggle" data-testid="medication-data-table" :is-loading="isLoading">
    <template slot="loading-indicator-placeholder">
      <div class="text-gray-700 font-semibold tracking-wide">
        <Loader :message="$t('MedicationDataTable.LoadingMessage')" />
      </div>
    </template>
    <template slot="no-content-placeholder">
      <div class="text-gray-700 font-semibold tracking-wide">
        {{ $t("MedicationDataTable.NoContentPlaceholder") }}
      </div>
    </template>
    <template slot="table-header-renderer-id-4" slot-scope="{ header, onColumnSortClick }">
      <TableHeaderLabel class="flex items-center">
        <span v-if="!isMedicareFormularySelected" class="flex-1">
          <Popover>
            <template slot="tooltip-trigger">
              <button :title="$t('MedicationDataTable.AverageCostIcon')" class="text-left">
                <span class="mr-1 link-color medication-table-header">
                  {{ header.text }}
                  <Icon name="info-circle" class="average-cost-icon link-color" />
                </span>
              </button>
            </template>
            <template slot="tooltip">
              <div class="font-normal leading-normal mb-2 average-cost-popover">
                {{ $t("MedicationDataTable.AverageCostPopover") }}
              </div>
            </template>
          </Popover>
        </span>
        <button v-if="!isMedicareFormularySelected" @click="onColumnSortClick" data-testid="sort-icon-4">
          <Icon name="sort" class="float-right" />
        </button>
        <span v-if="isMedicareFormularySelected" class="medication-table-header">
          {{ header.alternateText }}
        </span>
      </TableHeaderLabel>
    </template>
    <template slot="table-header-renderer-id-7" slot-scope="{ header }" style="padding-left: 0; padding-right: 0">
      <TableHeaderLabel class="flex items-center deletion-column-header" style="padding-left: 0; padding-right: 0">
        <span class="invisible w-8">
          {{ header.text }}
        </span>
      </TableHeaderLabel>
    </template>
    <template slot="cell-renderer-name" slot-scope="{ rowData: medication }">
      <TableDataCell>
        <div class="flex flex-row mb-1" v-if="!isCovered(medication) || medication.isGeneric">
          <NotCoveredTag v-if="!isCovered(medication)" class="mr-2" />
          <GenericMedicationTag v-if="medication.isGeneric" />
        </div>
        <p class="leading-6 font-medium text-base medication-name-label">
          {{ medication.name }}
        </p>
        <p class="leading-6 font-normal text-sm" v-if="medication.relatedNames">
          {{ getRelatedNamesLabel(medication) }}
        </p>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-formsAndDoses" slot-scope="{ rowData: medication }">
      <TableDataCell>
        <div class="medication-form-cell">
          <ListBox
            data-testid="medication-form-select"
            labelled-by="DataTable-TableHeader-1"
            :label="$t('MedicationDataTable.FormColumn')"
            @input="(value) => onFormSelect(value, medication)"
            :value="getSelectedFormValue(medication)"
            :options="getFormOptions(medication)"
            should-disable-if-one-option
          />
        </div>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-dose" slot-scope="{ rowData: medication }">
      <TableDataCell>
        <div class="medication-dose-cell">
          <ListBox
            data-testid="medication-dose-select"
            labelled-by="DataTable-TableHeader-2"
            :label="$t('MedicationDataTable.DoseColumn')"
            @input="(value) => onDoseSelect(value, medication)"
            :value="getSelectedDoseValue(medication)"
            :options="getSortedDoses(medication)"
            should-disable-if-one-option
          />
        </div>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-medicationClass.className" slot-scope="{ rowData: medication }">
      <TableDataCell class="medication-classname-cell">
        <router-link
          :to="{
            name: 'Drug Class Listing',
            params: { id: getMedicationClass(medication).gpi2 },
          }"
          class="leading-6 break-words"
        >
          {{ getMedicationClass(medication).className }}
        </router-link>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-averageCost" slot-scope="{ rowData: medication }">
      <TableDataCell class="text-right">
        <template v-if="!isCovered(medication) || (!isMedicareFormularySelected && !getAverageCost(medication))">
          <span class="text-sm leading-6 pricing-not-available" data-testid="medication-average-cost">{{ $t("MedicationDataTable.PricingNotAvailable") }}</span>
        </template>
        <template v-else>
          <span v-if="!isMedicareFormularySelected" data-testid="medication-average-cost" class="leading-6">
            {{ getAverageCost(medication) | lowPrecisionCurrencyOrNull }}
          </span>
          <span v-else>
            <CompareMedicarePlans :medication="medication" />
          </span>
        </template>
        <div v-if="!medication.isGeneric" class="mt-3 text-center">
          <CompareWithGeneric :medicationId="medication.id" />
        </div>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-drugTier" slot-scope="{ rowData: medication }">
      <TableDataCell data-testid="medication-drug-tier">
        <template v-if="isCovered(medication)">
          <DrugTier :coverageInfo="getSelectedCoverage(medication)" />
        </template>
      </TableDataCell>
    </template>
    <template slot="cell-renderer-coverage" slot-scope="{ rowData: medication }">
      <TableDataCell>
        <CoverageAlert :coverageInfo="getCoverageAlerts(medication)" />
      </TableDataCell>
    </template>
    <template slot="cell-renderer-deletionIcon" slot-scope="{ rowData: medication }">
      <TableDataCell class="trash-icon-cell">
        <button :title="$t('MedicationDataTable.DeleteMedication')" @click="onMedicationRemoveClick(medication)" data-testid="medication-remove-button">
          <Icon name="trash" />
        </button>
      </TableDataCell>
    </template>
  </DataTable>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getRelatedNamesLabel } from "@/util/getRelatedNamesLabel";
import DataTable, { TableHeaderLabel, useDefaultDataTableStyle } from "@/components/DataTable";
import DataTableStateReducer from "@/components/DataTable/src/DataTableStateReducer";
import TableDataCell from "@/components/DataTable/src/TableDataCell";
import Icon from "@/components/Icon";
import CompareMedicarePlans from "@/components/CompareMedicarePlans";
import CompareWithGeneric from "@/components/CompareWithGeneric";
import CoverageAlert from "@/components/CoverageAlert";
import DrugTier from "@/components/DrugTier";
import ListBox from "@/components/ListBox/ListBox";
import Popover from "@/components/Popover/Popover";
import Loader from "@/components/Loader";
import MedicationDataTableStateReducer from "@/components/MedicationDataTable/MedicationDataTableStateReducer";
import NotCoveredTag from "@/components/NotCoveredTag";
import GenericMedicationTag from "@/components/GenericMedicationTag";
import compareDoses from "@/util/compareDoses";
import { isMedicareFormulary } from "@/util/isMedicareFormulary";

useDefaultDataTableStyle();

export default {
  name: "MedicationDataTable",
  components: {
    GenericMedicationTag,
    NotCoveredTag,
    DrugTier,
    CoverageAlert,
    Icon,
    TableDataCell,
    TableHeaderLabel,
    DataTable,
    CompareMedicarePlans,
    CompareWithGeneric,
    ListBox,
    Popover,
    Loader,
  },
  props: {
    medications: {
      type: Array,
      required: true,
    },
    formsAndDoses: {
      type: Object,
      required: true,
    },
    selectedFormIndexes: {
      type: Object,
      required: true,
    },
    selectedDoseIndexes: {
      type: Object,
      required: true,
    },
    selectedFormularyId: {
      type: Number,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Data about the headers for the data table.
       */
      headers: [
        {
          id: 0,
          text: this.$t("MedicationDataTable.DrugNameColumn"),
          isSortable: true,
          associatedDataProperty: "name",
        },
        {
          id: 1,
          text: this.$t("MedicationDataTable.FormColumn"),
          associatedDataProperty: "formsAndDoses",
        },
        {
          id: 2,
          text: this.$t("MedicationDataTable.DoseColumn"),
          associatedDataProperty: "dose",
        },
        {
          id: 3,
          text: this.$t("MedicationDataTable.DrugClassColumn"),
          isSortable: true,
          associatedDataProperty: "medicationClass.className",
        },
        {
          id: 4,
          text: this.$t("MedicationDataTable.AverageCostColumn"),
          alternateText: this.$t("MedicationDataTable.MedicareCostColumn"),
          isSortable: true,
          associatedDataProperty: "averageCost",
        },
        {
          id: 5,
          text: this.$t("MedicationDataTable.DrugTierColumn"),
          isSortable: true,
          associatedDataProperty: "drugTier",
        },
        {
          id: 6,
          text: this.$t("MedicationDataTable.CoverageColumn"),
          associatedDataProperty: "coverage",
        },
        {
          id: 7,
          text: "Deletion", // I18N not needed; text not rendered to the page
          associatedDataProperty: "deletionIcon",
        },
      ],
      /**
       * The list of medications passed to the data table. Copied from props to allow sorting to mutate this state-owned
       * copy.
       */
      visibleMedications: [...this.medications],
    };
  },
  computed: {
    ...mapState(["selectedFormularyIndex"]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formularies() {
      return this.getFormulariesByPlanYear();
    },
    selectedFormularyName() {
      return this.formularies[this.selectedFormularyIndex].productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
  },
  methods: {
    ...mapActions(["removeFromCoverageList"]),
    getFormOptions(medication) {
      return this.formsAndDoses[medication.id][this.selectedFormularyId].map((forms) => forms.form);
    },
    getSortedDoses(medication) {
      const doses = this.formsAndDoses[medication.id][this.selectedFormularyId][this.selectedFormIndexes[medication.id]].doses.map((dose) => dose.dose);
      return doses.sort(compareDoses);
    },
    onFormSelect(value, medication) {
      const selectedFormIndex = this.formsAndDoses[medication.id][this.selectedFormularyId].findIndex((formAndDose) => formAndDose.form === value);

      this.$emit("form-select", {
        selectedFormIndex,
        medication,
      });
    },
    onDoseSelect(value, medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];

      const selectedDoseIndex = this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].doses.findIndex((dose) => dose.dose === value);

      this.$emit("dose-select", {
        selectedDoseIndex,
        medication,
      });
    },
    onMedicationRemoveClick(medication) {
      this.removeFromCoverageList({ medication });
    },
    onColumnSortToggle(event) {
      // Get the newly calculated state from the utility function
      let updatedState = DataTableStateReducer.handleColumnSortingChange({ headers: this.headers, data: this.visibleMedications }, event);

      if (event.header.associatedDataProperty === "averageCost") {
        updatedState = MedicationDataTableStateReducer.handleAverageCostColumnSortingChange(
          updatedState,
          event,
          this.selectedFormularyId,
          this.selectedFormIndexes,
          this.selectedDoseIndexes,
          this.formsAndDoses
        );
      } else if (event.header.associatedDataProperty === "drugTier") {
        updatedState = MedicationDataTableStateReducer.handleDrugTierColumnSortingChange(
          updatedState,
          event,
          this.selectedFormularyId,
          this.selectedFormIndexes,
          this.selectedDoseIndexes,
          this.formsAndDoses
        );
      }

      // Update your own state with the calculated state
      this.headers = updatedState.headers;
      this.visibleMedications = updatedState.data;
    },
    getSelectedFormValue(medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];
      return this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].form;
    },
    getSelectedDoseValue(medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];
      const selectedDoseIndex = this.selectedDoseIndexes[medication.id];
      return this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].doses[selectedDoseIndex].dose;
    },
    getRelatedNamesLabel,
    getSelectedCoverageInfo(medication) {
      return medication.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });
    },
    getSelectedCoverage(medication) {
      const selectedDose = this.getSelectedDose(medication);
      const selectedCoverageInfo = this.getSelectedCoverageInfo(medication);

      return selectedCoverageInfo?.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === selectedDose.medispanDrugDescriptorId;
      });
    },
    getSelectedDose(medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];
      const selectedDoseIndex = this.selectedDoseIndexes[medication.id];

      return this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].doses[selectedDoseIndex];
    },
    getSelectedMedicationDetails(medication) {
      const selectedDose = this.getSelectedDose(medication);

      return medication.medicationDetails.find((medicationDetail) => {
        return selectedDose.medispanDrugDescriptorId === medicationDetail.medispanDrugDescriptorId;
      });
    },
    getAverageCost(medication) {
      return this.getSelectedMedicationDetails(medication)?.averageCost;
    },
    getCoverageAlerts(medication) {
      return this.getSelectedCoverage(medication)?.coverageAlerts;
    },
    getTier(medication) {
      return this.getSelectedCoverage(medication)?.coverageTier?.tier;
    },
    isCovered(medication) {
      return !this.hasNotCoveredCoverageAlert(medication);
    },
    hasNotCoveredCoverageAlert(medication) {
      return this.getCoverageAlerts(medication)?.some((coverageAlert) => coverageAlert.durServiceDescription === "Not Covered");
    },
    getMedicationClass(medication) {
      const medicationDetails = this.getSelectedMedicationDetails(medication);

      return medication.medicationClasses.find((medicationClass) => {
        return medicationClass.gpi2 === medicationDetails.classId;
      });
    },
  },
  watch: {
    medications(medications) {
      // As new values come in from medications prop, create clone that we can perform mutations on for sorting
      this.visibleMedications = JSON.parse(JSON.stringify(medications));
    },
  },
};
</script>

<style>
/* Remove excess padding from the container within the Deletion column to make it more narrow */
#DataTable-TableHeader-7 > * {
  padding-left: 0;
  padding-right: 0;
}
</style>

<style scoped>
.medication-table-header {
  font-family: Roboto-Bold, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.medication-name-label {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.medication-form-cell {
  min-width: 120px;
}

.medication-dose-cell {
  min-width: 145px;
}

.medication-classname-cell {
  max-width: 205px;
  color: var(--BaseLink-Color);
}

.average-cost-icon {
  color: #004356;
}

.pricing-not-available {
  color: rgb(101, 33, 0);
}

.deletion-column-header {
  padding: 0;
}

.trash-icon-cell {
  text-align: center;
  vertical-align: middle;
  color: #004356;
}

.link-color {
  color: var(--BaseLink-Color);
}

.average-cost-popover {
  font-family: Roboto-Regular, sans-serif;
}
</style>
