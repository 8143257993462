<template>
  <div v-if="genericCoverage && !isGenericAlreadyInDrugList && hasGenericPricingAvailable">
    <Modal ref="savingsModal">
      <template slot="trigger">
        <BaseButton :text="$t('CompareWithGeneric.CompareWithGenericButton')" class="w-full font-bold text-xs" />
      </template>
      <template slot="body">
        <div class="text-left leading-normal">
          <div class="mb-2">
            <span class="text-base font-medium compare-with-generic-header">
              {{ $t("CompareWithGeneric.HeaderText") }}
            </span>
          </div>

          <div class="mb-2 w-full text-right">
            <span class="text-right font-bold text-sm tracking-wide leading-6 average-cost-label">
              {{ $t("CompareWithGeneric.CostHeading") }}
            </span>
          </div>

          <div class="mb-4">
            <NotCoveredTag v-if="hasNotCoveredCoverageAlertForBrand" class="mb-2" />
            <div class="flex">
              <div class="w-2/3">
                <span class="font-medium block text-base brand-medication-name">{{ this.brand.name }}</span>
                <span class="block text-sm leading-6">
                  {{ this.brandedMedicationDetails.form }}
                  {{ this.brandedMedicationDetails.dose }}
                </span>
                <span class="block text-sm leading-6">
                  {{ relatedBrandName }}
                </span>
              </div>
              <div class="flex flex-col items-center text-right w-1/3">
                <div v-if="isMedicareFormularySelected" class="w-full">
                  <span v-if="this.hasNotCoveredCoverageAlertForBrand" class="text-sm leading-6 pricing-not-available-label">
                    {{ $t("CompareWithGeneric.PricingNotAvailable") }}
                  </span>
                  <div v-else class="font-medium text-base average-cost-value">
                    {{ getMedicarePrice(this.brandedCoverage.coverageTier.tier, false) }}
                  </div>
                </div>
                <div v-else class="w-full">
                  <span v-if="!hasBrandPricingAvailable" class="text-sm leading-6 pricing-not-available-label">
                    {{ $t("CompareWithGeneric.PricingNotAvailable") }}
                  </span>
                  <div v-else class="font-medium text-base average-cost-value">
                    {{ this.brandedMedicationDetails.averageCost | lowPrecisionCurrencyOrNull }}
                  </div>
                </div>
                <div class="text-sm w-full text-right" v-if="hasBrandPricingAvailable">
                  {{ $t("TierTag.Message") }}
                  {{ this.brandedCoverage.coverageTier.tier }}
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4 text-center arrow-down-icon">
            <Icon name="arrow-down" type="far"></Icon>
          </div>
          <NotCoveredTag v-if="hasNotCoveredCoverageAlertForGeneric" class="mr-2" />
          <GenericMedicationTag />
          <div class="mb-6" v-if="generic">
            <div class="flex">
              <div class="w-2/3">
                <span class="font-medium block text-base generic-medication-name">{{ this.generic.name }}</span>
                <span class="block text-sm leading-6">
                  {{ this.genericMedicationDetails.form }}
                  {{ this.genericMedicationDetails.dose }}
                </span>
                <span class="block text-sm leading-6">
                  {{ relatedGenericName }}
                </span>
              </div>
              <div class="flex flex-col items-center text-right w-1/3">
                <div v-if="isMedicareFormularySelected" class="w-full">
                  <span v-if="hasNotCoveredCoverageAlertForGeneric" class="text-sm leading-6 pricing-not-available-label">
                    {{ $t("CompareWithGeneric.PricingNotAvailable") }}
                  </span>
                  <div v-else class="font-medium text-base average-cost-value">
                    {{ getMedicarePrice(this.genericCoverage.coverageTier.tier, true) }}
                  </div>
                </div>
                <div v-else class="w-full">
                  <span v-if="!hasGenericPricingAvailable" class="text-sm leading-6 pricing-not-available-label">
                    {{ $t("CompareWithGeneric.PricingNotAvailable") }}
                  </span>
                  <div v-else class="font-medium text-base average-cost-value">
                    {{ this.genericMedicationDetails.averageCost | lowPrecisionCurrencyOrNull }}
                  </div>
                </div>
                <div class="text-sm w-full text-right" v-if="hasGenericPricingAvailable">
                  {{ $t("TierTag.Message") }}
                  {{ this.genericCoverage.coverageTier.tier }}
                </div>
              </div>
            </div>
          </div>
          <BaseButton :text="$t('CompareWithGeneric.AddToListButton')" @click="addToCoverageList" class="w-full font-normal text-sm" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import BaseButton from "@/components/BaseButton/BaseButton";
import Icon from "@/components/Icon";
import { getRelatedNamesLabel } from "@/util/getRelatedNamesLabel";
import GenericMedicationTag from "@/components/GenericMedicationTag";
import NotCoveredTag from "@/components/NotCoveredTag";
import { ToastVariants } from "@/components/Toast";
import { isBlueAdvantageExtraFormulary, isBlueAdvantageFormulary, isBlueCarePlusFormulary, isMedicareFormulary } from "@/util/isMedicareFormulary";

export default {
  name: "CompareWithGeneric",
  components: { NotCoveredTag, GenericMedicationTag, Modal, BaseButton, Icon },
  props: {
    medicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      blueAdvantagePrices: {
        tier1: "$0",
        tier2: "$5-$10",
        tier3: "$28-$42",
        tier4: "$50-$94",
        tier5: "33% of the cost",
        tierSelectInsulin: "$28-35",
      },
      blueAdvantageExtraPrices: {
        generic: "25% or $0-$4.50",
        brand: "25% or $0-$11.20",
      },
      blueCarePlusPrices: {
        generic: "$0-$4.50",
        brand: "$0-$11.20",
      },
    };
  },
  methods: {
    ...mapActions("Toasts", ["addToast"]),
    ...mapActions(["makeRequestToAddToCoverageList"]),

    showNoMedicationCoverageToast(medicationName) {
      this.addToast({
        title: this.$t("Home.NoMedicationCoverageErrorTitle"),
        message: this.$t("Home.NoMedicationCoverageErrorMessage", [medicationName]),
        includeDismissButton: true,
        variant: ToastVariants.ERROR,
      });
    },
    addToCoverageList() {
      const { id, name } = this.generic;
      const selectedForm = this.genericMedicationDetails.form;
      const selectedDose = this.genericMedicationDetails.dose;
      this.makeRequestToAddToCoverageList({ id, selectedForm, selectedDose }).catch(() => {
        this.showNoMedicationCoverageToast(name);
      });
      this.$refs.savingsModal.toggleModal();
    },
    getSelectedDose(medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];
      const selectedDoseIndex = this.selectedDoseIndexes[medication.id];

      return this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].doses[selectedDoseIndex];
    },
    getSelectedCoverageInfo(medication) {
      return medication.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });
    },
    getSelectedCoverage(medication) {
      const selectedDose = this.getSelectedDose(medication);
      const selectedCoverageInfo = this.getSelectedCoverageInfo(medication);

      return selectedCoverageInfo?.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === selectedDose.medispanDrugDescriptorId;
      });
    },
    getSelectedMedicationDetails(medication) {
      const selectedDose = this.getSelectedDose(medication);

      return medication.medicationDetails.find((medicationDetail) => {
        return selectedDose.medispanDrugDescriptorId === medicationDetail.medispanDrugDescriptorId;
      });
    },
    getMedicarePrice(tier, isGeneric) {
      if (this.isBlueAdvantageFormularySelected) {
        return this.getBlueAdvantagePrice(tier);
      } else if (this.isBlueAdvantageExtraFormularySelected) {
        return this.getBlueAdvantageExtraPrice(isGeneric);
      } else {
        return this.getBlueCarePlusPrice(isGeneric);
      }
    },
    getBlueAdvantagePrice(tier) {
      switch (tier) {
        case 1:
          return this.blueAdvantagePrices.tier1;
        case 2:
          return this.blueAdvantagePrices.tier2;
        case 3:
          return this.blueAdvantagePrices.tier3;
        case 4:
          return this.blueAdvantagePrices.tier4;
        case 5:
          return this.blueAdvantagePrices.tier5;
      }
    },
    getBlueAdvantageExtraPrice(isGeneric) {
      return isGeneric ? this.blueAdvantageExtraPrices.generic : this.blueAdvantageExtraPrices.brand;
    },
    getBlueCarePlusPrice(isGeneric) {
      return isGeneric ? this.blueCarePlusPrices.generic : this.blueCarePlusPrices.brand;
    },
  },
  computed: {
    ...mapState([
      "coverageListMedications",
      "relatedResultMapping",
      "formsAndDoses",
      "selectedDoseIndexes",
      "selectedFormIndexes",
      "selectedFormularyIndex",
      "isThisPlanYear",
    ]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formularies() {
      return this.getFormulariesByPlanYear();
    },
    selectedFormularyId() {
      return this.formularies[this.selectedFormularyIndex].productScheduleId;
    },
    selectedFormularyName() {
      return this.formularies[this.selectedFormularyIndex].productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageFormularySelected() {
      return isBlueAdvantageFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageExtraFormularySelected() {
      return isBlueAdvantageExtraFormulary(this.selectedFormularyName);
    },
    isBlueCarePlusFormularySelected() {
      return isBlueCarePlusFormulary(this.selectedFormularyName);
    },
    brand() {
      return this.coverageListMedications.find((brand) => brand.id === this.medicationId);
    },
    generic() {
      if (this.isThisPlanYear) {
        return this.relatedResultMapping[this.brand.id][0];
      } else {
        return this.relatedResultMapping[this.brand.id][1];
      }
    },
    brandedMedicationDetails() {
      return this.getSelectedMedicationDetails(this.brand);
    },
    brandedCoverage() {
      const selectedDose = this.getSelectedDose(this.brand);

      const coverageInfo = this.brand.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });

      return coverageInfo?.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === selectedDose.medispanDrugDescriptorId;
      });
    },
    genericMedicationDetails() {
      if (!this.generic) {
        return;
      }

      const brandGpi14 = this.brandedMedicationDetails?.gpi14;

      const genericCoverage = this.generic.medicationDetails.find((medicationDetail) => {
        return medicationDetail.gpi14 && medicationDetail.gpi14 === brandGpi14;
      });

      if (!genericCoverage || !brandGpi14) {
        return;
      }

      return genericCoverage;
    },
    genericCoverage() {
      if (!this.generic) {
        return;
      }

      const genericCoverageInfo = this.generic.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });

      if (!this.genericMedicationDetails || !genericCoverageInfo) {
        return;
      }

      return genericCoverageInfo.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === this.genericMedicationDetails.medispanDrugDescriptorId;
      });
    },
    relatedBrandName() {
      return getRelatedNamesLabel.call(this, this.brand);
    },
    relatedGenericName() {
      return getRelatedNamesLabel.call(this, this.generic);
    },
    hasNotCoveredCoverageAlertForBrand() {
      return this?.brandedCoverage?.coverageAlerts?.some((coverageAlert) => coverageAlert.durServiceDescription === "Not Covered");
    },
    hasNotCoveredCoverageAlertForGeneric() {
      return this?.genericCoverage?.coverageAlerts?.some((coverageAlert) => coverageAlert.durServiceDescription === "Not Covered");
    },
    isGenericAlreadyInDrugList() {
      return this.coverageListMedications.some((medication) => medication.id === this.generic.id);
    },
    hasBrandPricingAvailable() {
      return !this.hasNotCoveredCoverageAlertForBrand && !!this.brandedMedicationDetails?.averageCost;
    },
    hasGenericPricingAvailable() {
      return !this.hasNotCoveredCoverageAlertForGeneric && !!this.genericMedicationDetails?.averageCost;
    },
  },
};
</script>

<style>
.average-cost-label {
  font-family: Roboto-Bold, sans-serif;
}

.pricing-not-available-label {
  color: rgb(101, 33, 0);
}

.compare-with-generic-header,
.brand-medication-name,
.generic-medication-name,
.average-cost-value {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.arrow-down-icon {
  color: rgb(105, 105, 105);
  font-size: 24px;
  font-weight: normal;
}
</style>
