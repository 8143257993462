import Vue from "vue";
import Vuex from "vuex";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import MedicationCoverageService from "@/services/medication-coverage/MedicationCoverageService";
import sanitizeMedicationCoverageResponse from "@/services/medication-coverage/sanitizeMedicationCoverageResponse";
import removeDuplicateDoses from "@/services/medication-coverage/removeDuplicateDoses";
import FormulariesService from "@/services/formularies/FormulariesService";
import MedicationsService from "@/services/medications/MedicationsService";
import MedicationClassesService from "@/services/medication-classes/MedicationClassesService";
import ToastModule from "@/store/modules/ToastModule";
import { NotificationTypes } from "@/util/NotificationTypes";
import { planYearDate, isMultiYearEnabled } from "@/util/getMultiPlanYearData";
import { isMedicareFormulary } from "@/util/isMedicareFormulary";
import moment from "moment";

Vue.use(Vuex);

const logRocketPlugin = createPlugin(LogRocket);

const store = new Vuex.Store({
  plugins: [logRocketPlugin],
  modules: {
    Toasts: ToastModule,
  },
  state: {
    isLoadingCoverageListMedication: false,
    isLoadingDrugClassList: false,
    idsOfMedicationCoveragesBeingLoaded: [],
    drugClassList: undefined,
    frequentMedications: undefined,
    coverageListMedications: [],
    formsAndDoses: {},
    selectedDoseIndexes: {},
    selectedFormIndexes: {},
    relatedResultMapping: {},
    defaultFormularyName: undefined,
    selectedFormularyIndex: undefined,
    selectedFormularyTypeIndex: undefined,
    coverageListCart: [],
    formularies: undefined,
    scrollToIndex: undefined,
    isThisPlanYear: true,
  },
  getters: {
    getFormulariesByPlanYear: (state) => () => {
      if (state.isThisPlanYear) {
        return state.formularies.filter((f) => moment(planYearDate.THIS_PLAN_YEAR_DATE).isBetween(f.effectiveStartDate, f.effectiveEndDate, "day", "[]"));
      } else {
        return state.formularies.filter((f) => moment(planYearDate.MULTI_PLAN_YEAR_DATE).isBetween(f.effectiveStartDate, f.effectiveEndDate, "day", "[]"));
      }
    },
  },
  actions: {
    makeRequestToAddToCoverageList({ dispatch, commit, state }, eventPayload) {
      const { id, selectedForm, selectedDose } = eventPayload;

      if (
        state.coverageListMedications.some((medication) => medication.id === id) ||
        state.idsOfMedicationCoveragesBeingLoaded.some((medicationId) => medicationId === id)
      ) {
        return;
      }

      return new Promise((resolve, reject) => {
        state.isLoadingCoverageListMedication = true;
        state.idsOfMedicationCoveragesBeingLoaded = [...state.idsOfMedicationCoveragesBeingLoaded, id];

        let getMedicationPromises = [
          MedicationCoverageService.getMedication({
            id,
            date: planYearDate.THIS_PLAN_YEAR_DATE,
          }),
        ];

        if (isMultiYearEnabled) {
          getMedicationPromises.push(
            MedicationCoverageService.getMedication({
              id,
              date: planYearDate.MULTI_PLAN_YEAR_DATE,
            })
          );
        }

        Promise.all(getMedicationPromises)
          .then((allMedications) => {
            let medication = allMedications[0];
            if (allMedications[1]) {
              if (medication && medication.coverageInfo) {
                medication.coverageInfo = allMedications[0].coverageInfo.concat(allMedications[1].coverageInfo);
                medication.relatedResults = allMedications[0].relatedResults.concat(allMedications[1].relatedResults);
              } else {
                medication = allMedications[1];
              }
            }

            medication = sanitizeMedicationCoverageResponse(medication, state.formularies);
            medication = removeDuplicateDoses(medication);

            if (medication && medication.id && medication.coverageInfo.length > 0) {
              dispatch("addToCoverageList", {
                medications: medication,
                selectedForm,
                selectedDose,
              });

              dispatch("addToFormsAndDoses", {
                medication,
              });

              if (selectedForm && selectedDose) {
                dispatch("setFormAndDoseToSpecificIndexes", {
                  medication,
                  selectedForm,
                  selectedDose,
                });
              }

              dispatch("setRelatedResultMapping", {
                id: medication.id,
                relatedResults: medication.relatedResults,
              });
              commit("reevaluateState");

              resolve({ addedNewEntry: true });
            } else {
              const errorMessage = `Was unable to retrieve coverage for medication with id: ${id}`;
              LogRocket.captureMessage(errorMessage);
              console.error(errorMessage);
              reject(errorMessage);
            }
          })
          .catch((error) => {
            LogRocket.captureException(error);
            const errorMessage = `Was unable to retrieve coverage for medication with id: ${id}`;
            console.error(errorMessage);
            reject(error);
          })
          .finally(() => {
            state.isLoadingCoverageListMedication = false;
            state.idsOfMedicationCoveragesBeingLoaded = state.idsOfMedicationCoveragesBeingLoaded.filter((medicationId) => medicationId !== id);
          });
      });
    },
    setFormAndDoseToSpecificIndexes({ commit, state }, eventPayload) {
      const { medication, selectedForm, selectedDose } = eventPayload;

      const selectedFormularyId = state.formularies[state.selectedFormularyIndex].productScheduleId;

      const selectedFormIndex = state.formsAndDoses[medication.id][selectedFormularyId].findIndex((formAndDoses) => formAndDoses.form === selectedForm);

      const selectedDoseIndex = state.formsAndDoses[medication.id][selectedFormularyId][selectedFormIndex].doses.findIndex(
        (dose) => dose.dose === selectedDose
      );

      if (selectedFormIndex !== -1) {
        commit("setSelectedFormIndex", {
          medication,
          selectedFormIndex,
        });
      }

      if (selectedDoseIndex !== -1) {
        commit("setSelectedDoseIndex", {
          medication,
          selectedDoseIndex,
        });
      }
    },
    makeRequestToGetFormularies({ dispatch, state }) {
      // Don't make repeated request if store already has the formularies
      if (!state.formularies) {
        let getFormulariesPromises = [FormulariesService.getFormularies({ date: planYearDate.THIS_PLAN_YEAR_DATE })];
        if (isMultiYearEnabled()) {
          getFormulariesPromises.push(FormulariesService.getFormularies({ date: planYearDate.MULTI_PLAN_YEAR_DATE }));
        }
        Promise.all(getFormulariesPromises).then((allFormularies) => {
          const formularies = allFormularies.flat();
          dispatch("setFormularies", { formularies });

          const index = formularies.findIndex((formulary) => formulary.productScheduleDescription === state.defaultFormularyName);
          if (index !== -1) {
            const typeIndex = isMedicareFormulary(formularies[index].productScheduleDescription) ? 1 : 0;
            dispatch("setSelectedFormularyTypeIndex", { typeIndex });
            dispatch("setSelectedFormularyIndex", { index });
          }
        });
      }
    },
    makeRequestToGetFrequentMedications({ commit, state }) {
      if (!state.frequentMedications) {
        MedicationsService.getFrequentMedications().then((frequentMedications) => {
          commit("setFrequentMedications", { frequentMedications });
        });
      }
    },
    makeRequestToGetDrugClassList({ commit, state }) {
      if (!state.drugClassList) {
        state.isLoadingDrugClassList = true;
        MedicationClassesService.getMedicationClasses()
          .then((medicationClasses) => {
            const drugClassList = [...medicationClasses];
            drugClassList.sort(compareMedicationClassesByClassName);

            commit("setDrugClassList", { drugClassList });
          })
          .finally(() => {
            state.isLoadingDrugClassList = false;
          });
      }
    },
    addToCoverageList({ commit }, eventPayload) {
      commit("addToCoverageList", eventPayload);
    },
    removeFromCoverageList({ commit }, eventPayload) {
      commit("removeFromCoverageList", eventPayload);
      commit("reevaluateState");
    },
    setRelatedResultMapping({ commit }, eventPayload) {
      commit("setRelatedResultMapping", eventPayload);
    },
    addToFormsAndDoses({ commit }, eventPayload) {
      commit("addToFormsAndDoses", eventPayload);
    },
    setSelectedFormIndex({ commit }, eventPayload) {
      commit("setSelectedFormIndex", eventPayload);
    },
    setSelectedDoseIndex({ commit }, eventPayload) {
      commit("setSelectedDoseIndex", eventPayload);
    },
    setSelectedFormularyIndex({ commit }, eventPayload) {
      commit("setSelectedFormularyIndex", eventPayload);
    },
    setSelectedFormularyTypeIndex({ commit }, eventPayload) {
      commit("setSelectedFormularyTypeIndex", eventPayload);
    },
    setDefaultFormularyName({ commit }, eventPayload) {
      commit("setDefaultFormularyName", eventPayload);
    },
    setFormularies({ commit }, eventPayload) {
      commit("setFormularies", eventPayload);
    },
    updateCoverageListCart({ commit }, eventPayload) {
      commit("updateCoverageListCart", eventPayload);
    },
    clearCoverageListCart({ commit }) {
      commit("clearCoverageListCart");
    },
    finalizeCoverageListCart({ commit, dispatch, state }) {
      const { coverageListCart } = state;
      const requestDispatches = coverageListCart.map((medication) => {
        return dispatch("makeRequestToAddToCoverageList", {
          id: medication.id,
        });
      });

      return Promise.allSettled(requestDispatches)
        .then((results) => {
          const indexesOfFailures = results.map((result, index) => (result.status === "rejected" ? index : undefined)).filter((index) => index !== undefined);

          const medicationsThatFailed = coverageListCart.filter((entry, index) => indexesOfFailures.indexOf(index) !== -1);

          const notifications = medicationsThatFailed.map((medication) => ({
            type: NotificationTypes.NO_COVERAGE_INFORMATION,
            data: { medication },
          }));

          commit("clearCoverageListCart");

          const numberOfSuccessfulAdditions = results.length - indexesOfFailures.length;

          const scrollIndex = results.some((result) => {
            return result.status !== "rejected";
          })
            ? state.coverageListMedications.length - numberOfSuccessfulAdditions
            : undefined;

          commit("setScrollToIndex", scrollIndex);

          return {
            notifications,
          };
        })
        .catch((err) => {
          // TODO: Potentially add DataDog integration here
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    setSelectedPlanYear({ commit }, eventPayload) {
      commit("setSelectedPlanYear", eventPayload);
    },
  },
  mutations: {
    addToCoverageList(state, eventPayload) {
      const { medications } = eventPayload;
      state.coverageListMedications.push(medications);
    },
    removeFromCoverageList(state, eventPayload) {
      const { medication } = eventPayload;
      state.coverageListMedications = state.coverageListMedications.filter((entry) => entry.id !== medication.id);
    },
    addToFormsAndDoses(state, eventPayload) {
      const { medication } = eventPayload;
      state.formsAndDoses[medication.id] = calculateFormsAndDoses(medication);
    },
    setRelatedResultMapping(state, eventPayload) {
      const { id, relatedResults } = eventPayload;
      Vue.set(state.relatedResultMapping, id, relatedResults);
    },
    setFrequentMedications(state, eventPayload) {
      const { frequentMedications } = eventPayload;
      state.frequentMedications = frequentMedications;
    },
    setDrugClassList(state, eventPayload) {
      const { drugClassList } = eventPayload;
      state.drugClassList = drugClassList;
    },
    setSelectedFormIndex(state, eventPayload) {
      const { medication, selectedFormIndex } = eventPayload;
      Vue.set(state.selectedFormIndexes, medication.id, selectedFormIndex);
      Vue.set(state.selectedDoseIndexes, medication.id, 0);
    },
    setSelectedDoseIndex(state, eventPayload) {
      const { medication, selectedDoseIndex } = eventPayload;
      Vue.set(state.selectedDoseIndexes, medication.id, selectedDoseIndex);
    },
    setSelectedFormularyIndex(state, eventPayload) {
      const { index } = eventPayload;
      state.selectedFormularyIndex = index;
    },
    setSelectedFormularyTypeIndex(state, eventPayload) {
      const { typeIndex } = eventPayload;
      state.selectedFormularyTypeIndex = typeIndex;
    },
    setDefaultFormularyName(state, eventPayload) {
      const { name } = eventPayload;
      state.defaultFormularyName = name;
    },
    setFormularies(state, eventPayload) {
      const { formularies } = eventPayload;
      state.formularies = formularies;
    },
    updateCoverageListCart(state, eventPayload) {
      const { medication } = eventPayload;

      if (state.coverageListCart.some((entry) => entry.id === medication.id)) {
        state.coverageListCart = state.coverageListCart.filter((entry) => entry.id !== medication.id);
      } else {
        state.coverageListCart = [...state.coverageListCart, medication];
      }
    },
    clearCoverageListCart(state) {
      state.coverageListCart = [];
    },
    reevaluateState(state) {
      state.selectedFormIndexes = getInitialSelectedFormIndexes(state.coverageListMedications, state.selectedFormIndexes);

      state.selectedDoseIndexes = getInitialSelectedDoseIndexes(state.coverageListMedications, state.selectedDoseIndexes);
    },
    setScrollToIndex(state, value) {
      state.scrollToIndex = value;
    },
    setSelectedPlanYear(state, eventPayload) {
      const { isThisPlanYear } = eventPayload;
      state.isThisPlanYear = isThisPlanYear;
    },
  },
});

export default store;

/**
 * Returns an object that provides the first form to show to the user. Each key is based off the medications' ids.
 *
 * @param {Array} medications - List of medications.
 * @param {Object} previousSelectionState - If there was a previous selection state, it can be passed here as a a basis
 *    for the new state.
 */
function getInitialSelectedFormIndexes(medications, previousSelectionState) {
  const selectionState = { ...previousSelectionState };

  if (!medications) {
    return;
  }

  Object.entries(selectionState).forEach(([key]) => {
    if (!medications.some((entry) => entry.id !== key)) {
      delete selectionState[key];
    }
  });

  medications.forEach((medication) => {
    if (!selectionState[medication.id]) {
      selectionState[medication.id] = 0;
    }
  });

  return selectionState;
}

/**
 * Returns an object with an explicitly set value at keys for each medication. If no selection has been made for a
 * medication, this utility will explicitly assign null for that value.
 *
 * @param {Array} medications - List of medications to initialize spots for
 * @param {Object} previousSelectionState - If there was a previous selection state, it can be passed here as a a basis
 *    for the new state.
 *
 * @returns {Object} Object containing the dose form selections, keyed off of the medications' ids.
 */
function getInitialSelectedDoseIndexes(medications, previousSelectionState = {}) {
  const selectionState = { ...previousSelectionState };

  if (!medications) {
    return;
  }

  Object.entries(selectionState).forEach(([key]) => {
    if (!medications.some((entry) => entry.id !== key)) {
      delete selectionState[key];
    }
  });

  medications.forEach((medication) => {
    if (!selectionState[medication.id]) {
      selectionState[medication.id] = 0;
    }
  });

  return selectionState;
}

function compareMedicationClassesByClassName(a, b) {
  const name1 = a.className.toLowerCase();
  const name2 = b.className.toLowerCase();
  if (name1 < name2) {
    return -1;
  }

  if (name1 > name2) {
    return 1;
  }

  return 0;
}

/**
 * Returns an object with all the forms and the corresponding doses to go with them.
 *
 * @param {Array} medication - The medication that needs the form and doses calculated.
 *
 * @returns {Object} Object containing the forms and corresponding doses, keyed off of the medications' ids containing
 *                          an object keyed off the formulary id.
 */
function calculateFormsAndDoses(medication) {
  return medication.coverageInfo
    .map((coverageInfo) => {
      const medicationDetailIds = coverageInfo.coverages.map((coverage) => coverage.medispanDrugDescriptorId);

      const medicationDetails = medication.medicationDetails.filter((medicationDetail) =>
        medicationDetailIds.includes(medicationDetail.medispanDrugDescriptorId)
      );

      const formsAndDoses = [];

      const forms = medicationDetails.map((forms) => forms.form);
      const uniqueForms = new Set(forms);
      const uncoveredForms = [...uniqueForms];

      uncoveredForms.forEach((form) => {
        const formsObject = {
          form: form,
          doses: [],
        };

        const uncoveredDoses = medicationDetails.filter((forms) => form === forms.form);

        formsObject.doses = uncoveredDoses.map((dose) => ({
          medispanDrugDescriptorId: dose.medispanDrugDescriptorId,
          dose: dose.dose,
        }));

        formsAndDoses.push(formsObject);
      });

      return { coverageInfo, formsAndDoses };
    })
    .reduce((acc, current) => {
      const { coverageInfo, formsAndDoses } = current;
      acc[coverageInfo.productScheduleId] = formsAndDoses;

      return acc;
    }, {});
}
