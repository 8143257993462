import axios from "axios";

/**
 * Utilities for retrieving information related to medication classes.
 */
const MedicationClassesService = {
  getMedicationClasses: async () => {
    const response = await axios.get("/medication-classes");
    return response.data.data;
  },
  getMedicationClass: async ({ id }) => {
    const response = await axios.get(`/medication-classes/${id}`);
    return response.data.data;
  },
};

export default MedicationClassesService;
