<template>
  <div class="relative flex justify-center items-center search-wrapper" @keydown="handleSearchContainerKeyDown">
    <div class="w-full h-auto relative">
      <Icon class="absolute top-0 mt-4 ml-4 z-10 search-icon" name="search" />
      <label>
        <input
          data-testid="search-bar"
          type="text"
          v-model="searchQuery"
          :placeholder="placeholder"
          @keyup="handleSearchBarKeyUp"
          @focus="handleFocus"
          class="h-12 w-full p-4 pr-8 search-bar"
          :class="{ 'search-bar-dropdown-is-open': isOpen }"
          ref="searchInput"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
        />
      </label>
      <button data-testid="clear-input-icon" class="clear-input-icon" :class="{ hidden: searchQuery.length === 0 }" @click="clearSearch" tabindex="-1">
        <Icon class="absolute top-0 right-0 mt-4 mr-4 z-10 clear-input-icon" name="times-circle" tabindex="0" />
      </button>
      <button v-if="isOpen" @click="closeSearchContainer" class="fixed inset-0 w-full h-full cursor-default" tabindex="-1" />
      <div v-if="isOpen">
        <div v-if="isLoading" data-testid="search-loading" class="absolute w-full px-4 search-loading-container">
          <div class="search-loading-wrapper">
            <Loader class="search-loading-indicator" :message="$t('Search.LoadingMessage')" />
          </div>
        </div>
        <div data-testid="search-dropdown" class="search-dropdown search-dropdown-focus-outline px-4" v-if="noResults || searchSuggestions.length > 0">
          <div class="search-results-container">
            <div v-if="noResults" data-testid="search-dropdown-no-results">
              <div class="h-auto w-full py-4 flex justify-start">
                {{ this.noResultsMessageAdjusted }}
              </div>
            </div>
            <div v-if="searchSuggestions.length > 0">
              <div
                v-for="(searchSuggestion, index) in searchSuggestions"
                :key="searchSuggestion.id"
                @focusin="isFocusOnResults = true"
                @focusout="isFocusOnResults = false"
              >
                <button
                  ref="searchResultElements"
                  class="h-auto w-full py-4 flex justify-start search-entry-button"
                  @focus="handleSearchResultFocus(index)"
                  @click="selectSearchElement(searchSuggestion)"
                  data-testid="search-entry"
                >
                  <span class="text-left mr-4 leading-6 search-entry" v-html="getDisplayForSearchSuggestion(searchSuggestion)" />
                  <label class="text-xs leading-6 whitespace-no-wrap add-to-list-label">{{ $t("Search.AddToListLabel") }}</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicationsService from "@/services/medications/MedicationsService";
import debounce from "lodash.debounce";
import Icon from "@/components/Icon";
import Loader from "@/components/Loader";
import { getRelatedNamesLabel } from "@/util/getRelatedNamesLabel";

export default {
  name: "Search",
  components: { Icon, Loader },
  inject: ["StateMachine"],
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    /**
     * String to display when there are no results found. If you'd like to include the search query, include the
     * substring "%SearchQuery%" in the value provided.
     */
    noResultsMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      isOpen: false,
      searchSuggestions: [],
      isLoading: false,
      noResults: false,
      isWaiting: undefined,

      /**
       * Number representing the search result element that should be focused. If nothing should be focused, this value
       * should be undefined.
       */
      focusedResultIndex: undefined,

      /**
       * Boolean indicating if the focus is currently on the search results. If the focus is somewhere else, such as in
       * the input field or the Clear Input button, this should be false.
       */
      isFocusOnResults: false,
    };
  },
  computed: {
    noResultsMessageAdjusted() {
      return this.noResultsMessage.replace("%SearchQuery%", `'${this.searchQuery}'`);
    },
  },
  watch: {
    searchSuggestions() {
      // If there is nothing to focus
      if (this.searchSuggestions.length === 0) {
        this.focusedResultIndex = undefined;
      }
    },
    focusedResultIndex() {
      if (this.$refs.searchResultElements && this.$refs.searchResultElements[this.focusedResultIndex]) {
        this.$refs.searchResultElements[this.focusedResultIndex].focus();
      }
    },
    searchQuery: function () {
      if (!this.isLoading) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    handleSearchBarKeyUp(event) {
      if (["ArrowUp", "ArrowDown", "Escape", "Esc"].indexOf(event.key) === -1) {
        if (!this.isLoading) {
          this.performSearch();
        } else if (this.isLoading && !this.isWaiting) {
          this.isWaiting = setInterval(() => {
            if (!this.isLoading) {
              this.isWaiting = clearInterval(this.isWaiting);
              this.performSearch();
            }
          }, 200);
        }
      }
    },
    handleSearchResultFocus(index) {
      if (this.focusedResultIndex !== index) {
        this.focusedResultIndex = index;
      }
    },
    handleSearchContainerKeyDown(event) {
      if (event.key === "ArrowDown") {
        event.preventDefault();

        if (!this.isOpen) {
          this.performSearch();
        }

        // If the focus isn't already on a result, make sure the element that is focused is the first element
        if (!this.isFocusOnResults) {
          this.focusedResultIndex = 0;
        } else {
          this.incrementFocusIndexWithinBounds();
        }
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();

        // If the focus is already on the first element and we hit the up arrow, move focus to the input field
        if (this.focusedResultIndex === 0) {
          this.$refs.searchInput.focus();
          this.focusedResultIndex = undefined;
        } else {
          this.decrementFocusIndexWithinBounds();
        }
      }
    },
    decrementFocusIndexWithinBounds() {
      if (this.focusedResultIndex > 0) {
        this.focusedResultIndex--;
      }
    },
    incrementFocusIndexWithinBounds() {
      if (this.focusedResultIndex < this.searchSuggestions.length - 1) {
        this.focusedResultIndex++;
      }
    },
    performSearch: debounce(function () {
      if (this.searchQuery === "") {
        this.isOpen = false;
        this.searchSuggestions = [];
        return;
      }
      this.searchSuggestions = [];
      this.noResults = false;
      this.isOpen = true;

      this.isLoading = true;
      MedicationsService.getMedications({
        name: this.searchQuery,
        sortBy: MedicationsService.Constants.SortBy.RELEVANCE,
      })
        .then((response) => {
          const searchResults = response.data;
          if (searchResults.length === 0) {
            this.noResults = true;
          } else {
            this.noResults = false;
            this.searchSuggestions = searchResults;
          }
        })
        .catch(() => {
          this.searchSuggestions = [];
          this.noResults = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 250),
    closeSearchContainer() {
      this.isOpen = false;
    },
    selectSearchElement(selectedSearchElement) {
      this.isOpen = false;
      this.searchQuery = "";
      this.searchSuggestions = [];
      this.$emit("search-element-selected", selectedSearchElement);
    },
    getDisplayForSearchSuggestion(searchSuggestion) {
      let text = searchSuggestion.name;

      if (searchSuggestion.relatedNames && searchSuggestion.relatedNames.length > 0) {
        text += ` ${getRelatedNamesLabel.call(this, searchSuggestion)}`;
      }

      return text.replace(new RegExp(this.searchQuery, "gi"), (match) => {
        return `<span class="highlightedText">${match}</span>`;
      });
    },
    clearSearch() {
      this.searchQuery = "";
      this.isOpen = false;
      this.searchSuggestions = [];
    },
    handleFocus() {
      if (!this.isOpen) {
        this.performSearch();
      }
      this.StateMachine.send("SEARCH_FOCUS");
    },
  },
  created() {
    const handleEscapeKey = (event) => {
      if (event.key === "Esc" || event.key === "Escape") {
        this.isOpen = false;
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscapeKey);
    });
  },
};
</script>

<style>
.highlightedText {
  background-color: var(--BaseSearch-Highlighted-BackgroundColor);
}

.search-bar {
  color: var(--BaseSearch-Text-Color);
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding-left: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.search-bar::placeholder {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.search-icon {
  color: var(--BaseSearch-SearchIcon-Color);
}

.clear-input-icon {
  color: rgb(94, 94, 94);
}

.clear-input-icon:focus {
  outline: none;
  border: 1px solid #4d90fe;
  -webkit-box-shadow: 0px 0px 5px #4d90fe;
  box-shadow: 0px 0px 5px #4d90fe;
  border-radius: 100%;
}

.search-loading-container {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 50;
}

.search-loading-wrapper {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
  padding: 32px 0;
}

.search-bar-dropdown-is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-dropdown {
  backdrop-filter: blur(20px);
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 50;
}

.search-results-container {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
}

.search-entry {
  color: var(--BaseLink-Color);
}

.add-to-list-label {
  color: var(--BaseSearch-AddToListLabel-TextColor);
  display: none;
}

/**
 * All styles for focus and hover
 */
.search-entry-button:hover .search-entry {
  color: rgb(0, 67, 86);
}

.search-entry-button:focus {
  outline: none;
  border-radius: 4px;
  border: 1px solid rgb(0, 128, 164);
}

.search-entry-button:focus-within .add-to-list-label,
.search-entry-button:hover .add-to-list-label {
  display: block;
}

.search-entry-button:focus-within .search-entry {
  color: rgb(0, 67, 86);
}

.search-wrapper:focus-within .search-bar {
  outline: none;
  border: 1px solid var(--BaseSearch-FocusOutline-Color);
}

.search-wrapper:focus-within .search-bar-dropdown-is-open {
  border-bottom: 0;
}

.search-wrapper:focus-within .search-dropdown {
  border: 1px solid var(--BaseSearch-FocusOutline-Color);
  border-top: 0;
}

.search-wrapper:focus-within .search-loading-container {
  border: 1px solid var(--BaseSearch-FocusOutline-Color);
  border-top: 0;
}
</style>
