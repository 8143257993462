<template>
  <DefaultPageLayout>
    <template slot="header">
      <nav
        class="flex items-center justify-between flex-wrap p-6 shadow flex-row h-24"
      >
        <div class="flex flex-row">
          <div class="bg-gray-200 rounded-full h-4 w-12 p-2 mr-4"></div>
          <div class="bg-gray-200 rounded-full h-4 w-64 p-2"></div>
        </div>
        <div>
          <div class="bg-gray-200 rounded-full h-4 w-12 p-2 mr-4"></div>
        </div>
      </nav>
    </template>
    <template slot="content">
      <slot />
    </template>
  </DefaultPageLayout>
</template>

<script>
import DefaultPageLayout from "@/components/WhiteLabelProvider/DefaultPageLayout";

/**
 * Component that shows an UI "skeleton" with pseudo components while loading the real white label handler.
 */
export default {
  name: "WhiteLabelLoadingSkeleton",
  components: { DefaultPageLayout },
};
</script>

<style scoped></style>
