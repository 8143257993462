<template functional>
  <tr class="DataTable-TableDataRow" data-testid="DataTable-TableDataRow">
    <slot />
  </tr>
</template>

<script>
export default {
  name: "TableDataRow",
};
</script>
