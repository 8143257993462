<template>
  <div data-testid="modal" class="inline">
    <div @click="toggleModal" class="inline">
      <slot name="trigger"></slot>
    </div>
    <focus-trap v-model="isOpen">
      <div>
        <transition
          name="fade"
          enter="fade-enter-active"
          leave="fade-leave-active"
        >
          <div class="modal fixed w-full h-full top-0 left-0" v-if="isOpen">
            <div
              class="fixed w-full h-full"
              data-testId="modal-mask"
              @click="toggleModal"
            ></div>
          </div>
        </transition>
        <transition name="slide" mode="out-in">
          <div
            aria-modal="”true”"
            class="modal-body fixed h-full"
            v-if="isOpen"
            data-testid="modal-body"
          >
            <div class="close-icon-container">
              <button
                class="float-right close-icon relative"
                data-testId="close-icon"
                @click="toggleModal"
              >
                <Icon name="times" size="lg"></Icon>
              </button>
            </div>
            <slot name="body"></slot>
          </div>
        </transition>
      </div>
    </focus-trap>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import VueScreenSize from "vue-screen-size";
import { FocusTrap } from "focus-trap-vue";

export default {
  name: "Modal",
  components: { Icon, FocusTrap },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      const body = document.body;
      body.style.height = "";
      body.style.overflowY = "";
    },
    openModal() {
      this.isOpen = true;
      const body = document.body;
      body.style.height = "100vh";
      body.style.overflowY = "hidden";
    },
    toggleModal() {
      if (this.isOpen) {
        this.closeModal();
      } else {
        this.openModal();
      }
    },
  },
  created() {
    const handleEscapeKey = (event) => {
      if (event.key === "Esc" || event.key === "Escape") {
        this.closeModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscapeKey);
    });
  },
  watch: {
    $vssWidth() {
      this.closeModal();
    },
  },
};
</script>

<style>
.modal {
  background-color: rgba(111, 125, 137, 0.7);
  z-index: 10000;
}
.modal-body {
  width: 25%;
  min-width: 400px;
  background-color: #ffffff;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  padding-left: 32px;
  padding-right: 32px;
  overflow: scroll;
}

.close-icon-container {
  padding-bottom: 40px;
}

.close-icon {
  right: -16px;
  top: 16px;
  color: #5e5e5e;
}

/* Animation Classes */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s ease-in-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-to {
  transform: translateX(0);
}

.slide-leave {
  transform: translateX(0);
}

.slide-leave-to {
  transform: translateX(100%);
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .modal-body {
    min-width: unset;
    width: 100%;
    max-height: 100%;
    bottom: 0;
    top: unset;
  }

  .slide-enter {
    transform: translateY(100%);
  }

  .slide-enter-to {
    transform: translateY(0);
  }

  .slide-leave {
    transform: translateY(0);
  }

  .slide-leave-to {
    transform: translateY(100%);
  }
}
</style>
