/**
 * Function for determining which client whitelabel should be shown.
 *
 * @param url - The url to base the key off of. This parameter is only exposed for testing purposes; the default
 *              value automatically handles retrieving it based on the browser.
 *
 * @returns {String} String representing the client whitelabel to use.
 */
export default function getWhiteLabelPrefix(url = document.URL.toLowerCase()) {
  // If running Cypress tests, don't white label so that we see the standard experience
  if (window.Cypress && url.includes("localhost")) {
    return undefined;
  }

  return "BTN";
}
