/**
 * Function for determining which client should be used when retrieving data from the backend.
 *
 * @param url - The url to base the key off of. This parameter is only exposed for testing purposes; the default
 *              value automatically handles retrieving it based on the browser.
 *
 * @returns {String} String representing the client whitelabel to use.
 */
import getWhiteLabelPrefix from "@/util/getWhiteLabelPrefix";

export default function getApiEndpointClientPrefix(
  url = document.URL.toLowerCase()
) {
  const whiteLabelPrefix = getWhiteLabelPrefix(url);
  if (whiteLabelPrefix) {
    return whiteLabelPrefix;
  }

  if (url.includes("localhost")) {
    return "BTN";
  }

  return "BTN";
}
