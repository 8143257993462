<template>
  <span
    class="font-semibold leading-4 text-xs rounded-sm px-1 whitespace-no-wrap generic-medication-tag"
    >{{ $t("GenericMedicationTag.Message") }}</span
  >
</template>

<script>
export default {
  name: "GenericMedicationTag",
};
</script>

<style scoped>
.generic-medication-tag {
  width: min-content;
  background-color: rgba(51, 51, 51, 0.9);
  color: rgb(236, 236, 236);
}
</style>
