<template>
  <div v-if="WhiteLabelSettings.coverageInformationDisclaimer" class="flex justify-center items-start p-4 md:p-1 md:items-center disclaimer">
    <div class="h-full flex justify-start items-start pt-1 md:pt-0">
      <Icon name="exclamation-triangle" type="far" aria-hidden="true" />
    </div>
    <span v-if="isMedicareFormularySelected" class="pl-2 text-sm leading-6 font-normal">{{ WhiteLabelSettings.coverageInformationMedicareDisclaimer }}</span>
    <span v-else class="pl-2 text-sm leading-6 font-normal">{{ WhiteLabelSettings.coverageInformationDisclaimer }}</span>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { mapGetters, mapState } from "vuex";
import { isMedicareFormulary } from "@/util/isMedicareFormulary";

export default {
  name: "CoverageInformationDisclaimer",
  components: { Icon },
  inject: ["WhiteLabelSettings"],
  computed: {
    ...mapState(["selectedFormularyIndex"]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formularies() {
      return this.getFormulariesByPlanYear();
    },
    selectedFormularyName() {
      return this.formularies[this.selectedFormularyIndex].productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
  },
};
</script>

<style scoped>
.disclaimer {
  font-family: Roboto-Regular, sans-serif;
  background-color: var(--LandingPage-CoverageInformationDisclaimer-BackgroundColor);
  color: var(--LandingPage-CoverageInformationDisclaimer-Color);
  min-height: 32px;
}

@media screen and (max-width: 1000px) {
  .disclaimer {
    margin-bottom: 16px;
    border-radius: 4px;
  }
}
</style>
