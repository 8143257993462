<template>
  <div class="content-container">
    <div class="content px-6 py-8"><slot /></div>
  </div>
</template>

<script>
/**
 * Wrapper component that provides a default implementation for padding and width on various screen sizes.
 */
export default {
  name: "Container",
};
</script>

<style scoped>
.content-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  width: 100%;
}

@media screen and (min-width: 1240px) {
  .content {
    max-width: 1240px;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
