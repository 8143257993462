import sortFormulariesBasedOnClientPreferences from "@/util/sortFormulariesBasedOnClientPreferences";
import getWhiteLabelPrefix from "@/util/getWhiteLabelPrefix";
import { adjudifyAxios } from "@/util/setupAdjudifyAxios";
import getAdjudifyApiEndpointClientPrefix from "@/util/getAdjudifyApiEndpointClientPrefix";

/**
 * Utilities for retrieving information related to formularies.
 */
const FormulariesService = {
  async getFormularies({ date }) {
    const client = getAdjudifyApiEndpointClientPrefix();
    const response = await adjudifyAxios.get(`/adjudify/tiercheck/formularies`, {
      params: {
        client: client,
        effectiveDate: date,
      },
    });
    const clientPrefix = getWhiteLabelPrefix();

    if (!clientPrefix) {
      return response.data;
    }

    // Checks if the client has a specific order they want to see the formularies in
    const whiteLabelSettings = require("@/components/WhiteLabelProvider/clients/" + clientPrefix + "/Settings.json");

    if (whiteLabelSettings?.formularySortOrderPreference) {
      return sortFormulariesBasedOnClientPreferences(response.data, whiteLabelSettings.formularySortOrderPreference);
    }

    return response.data;
  },
};

export default FormulariesService;
