/**
 * Vuex module for holding all methods and data related to rendering a Toast within the application.
 */
const ToastModule = {
  namespaced: true,
  state: {
    counter: 0,
    activeToasts: [],
  },
  actions: {
    /**
     * Adds a toast to the screen.
     *
     * @param {Object} context - The Vuex Context
     * @param {Object} settings - The settings for the toast to present. This should match the props of the Toast
     *    component, excluding the id. This function provides the id internally.
     */
    addToast({ commit }, settings) {
      commit("addToast", settings);
    },

    /**
     * Dismisses a toast from the screen.
     *
     * @param {Object} context - The Vuex Context
     * @param {String|Number} id - The id of the
     */
    dismissToast({ commit }, id) {
      commit("dismissToast", id);
    },

    /**
     * Removes all toasts from the screen.
     *
     * @param {Object} context - The Vuex Context
     */
    clearAllToasts({ commit }) {
      commit("clearAllToasts");
    },
  },
  mutations: {
    addToast(state, settings = {}) {
      const id = state.counter;
      const toastSettingsWithId = { id, ...settings };
      state.counter++;
      state.activeToasts.push(toastSettingsWithId);

      // If a dismiss button is not included, close the Toast on a timer
      if (!settings.includeDismissButton) {
        setTimeout(() => {
          state.activeToasts = state.activeToasts.filter(
            (toast) => toast.id !== id
          );
        }, 2000);
      }
    },
    dismissToast(state, id) {
      state.activeToasts = state.activeToasts.filter(
        (toast) => toast.id !== id
      );
    },
    clearAllToasts(state) {
      state.activeToasts = [];
    },
  },
};

export default ToastModule;
