import Axios from "axios";

export default function setupAxios(environment = "production") {
  if (
    environment === "development" ||
    environment === "test" ||
    (window.Cypress && window.location.href.includes("localhost")) // Only added if running Cypress test
  ) {
    Axios.defaults.baseURL = "/api";
  } else {
    Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }
}
