<template>
  <div v-if="options.length <= 1 && shouldDisableIfOneOption">
    <div
      class="leading-normal text-left px-4 py-2 text-sm w-full rounded-sm flex flex-row items-center justify-between listbox-trigger-button-disabled"
      data-testid="listbox-disabled-dropdown"
      :aria-labelledby="labelledBy"
    >
      {{ value }}
      <Icon name="chevron-down" />
    </div>
  </div>
  <div v-else>
    <div class="relative w-full" @keydown="handleListboxKeyDown" @focusout="handleFocusOut" ref="listbox">
      <slot name="trigger" :onClick="openMenu" :isOpen="isOpen" :disabled="disabled" :labelledBy="labelledBy">
        <div class="listbox-trigger-button-wrapper">
          <button
            @click="openMenu"
            ref="listboxTriggerButton"
            class="leading-normal text-left px-4 py-2 text-sm w-full rounded-sm flex flex-row items-center justify-between listbox-trigger-button"
            :class="{
              'listbox-trigger-button-focus-outline': isOpen,
              'listbox-trigger-button-disabled': disabled,
            }"
            aria-haspopup="listbox"
            :aria-expanded="isOpen"
            :aria-labelledby="labelledBy"
            :aria-disabled="disabled"
            data-testid="listbox-trigger"
          >
            {{ value }}
            <Icon
              name="chevron-down"
              class="listbox-trigger-button-chevron"
              :class="{
                'listbox-trigger-button-chevron-disabled': disabled,
              }"
              v-if="!isOpen"
            />
            <Icon name="chevron-up" class="listbox-trigger-button-chevron" v-else />
          </button>
        </div>
      </slot>
      <div class="absolute pt-3 w-full listbox-list-container" v-if="isOpen" @keydown.stop="handleListboxOptionKeyDown" v-click-outside="onClickOutsideList">
        <ListBoxList :labelled-by="labelledBy" :label="label" @close-icon-click="handleCloseIconClick">
          <ListBoxOption
            v-for="(option, index) in options"
            :key="option"
            :option="option"
            :selected="isOptionSelected(option)"
            :active="isOptionActive(index)"
            @mouseover="setActiveOptionIndex(index)"
            @click.native.prevent="handleOptionClick(option)"
          >
            {{ option }}
          </ListBoxOption>
        </ListBoxList>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import Icon from "@/components/Icon";
import ListBoxOption from "@/components/ListBox/ListBoxOption";
import ListBoxList from "@/components/ListBox/ListBoxList";
import VueScreenSize from "vue-screen-size";

export default {
  name: "ListBox",
  components: { ListBoxList, ListBoxOption, Icon },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    /**
     * HTML Id to identify the element that provides context for / labels this ListBox.
     */
    labelledBy: {
      type: String,
      required: true,
    },

    /**
     * String of the text-content for a label that will be displayed when the ListBox is opened on mobile.
     */
    label: {
      type: String,
      required: true,
    },

    /**
     * Whether or not this element should be disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether or not this element should disable the dropdown if only one value is present
     */
    shouldDisableIfOneOption: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isOpen: false,
      activeOptionIndex: 0,
    };
  },
  methods: {
    openMenu() {
      if (!this.disabled && !this.isOpen) {
        this.isOpen = true;
        this.initializeActiveOptionIndex();
      }
    },
    closeMenu() {
      if (this.isOpen) {
        this.isOpen = false;
        // Ensures that pressing space after escaping doesn't select an option that was previously highlighted
        // Developer note: Left here rather than in watcher to ensure this gets set before DOM rerenders
        this.initializeActiveOptionIndex();
      }
    },
    isOptionSelected(option) {
      return this.value === option;
    },
    handleOptionClick(option) {
      this.handleOptionSelection(option);
      this.setFocusOnTriggerButton();
    },
    handleOptionSelection(option) {
      this.$emit("input", option);
      this.closeMenu();
    },
    isOptionActive(index) {
      return this.activeOptionIndex === index;
    },
    setActiveOptionIndex(index) {
      this.activeOptionIndex = index;
    },
    initializeActiveOptionIndex() {
      this.activeOptionIndex = this.options.findIndex(this.isOptionSelected);
    },
    moveToPreviousOption() {
      if (this.activeOptionIndex > 0) {
        this.activeOptionIndex--;
      }
    },
    moveToNextOption() {
      if (this.activeOptionIndex < this.options.length - 1) {
        this.activeOptionIndex++;
      }
    },
    selectCurrentlyHighlightedElement() {
      const option = this.options[this.activeOptionIndex];
      this.handleOptionSelection(option);
    },
    setFocusOnTriggerButton() {
      if (this.$refs.listboxTriggerButton) {
        this.$refs.listboxTriggerButton.focus();
      }
    },
    handleCloseIconClick() {
      this.closeMenu();
      this.setFocusOnTriggerButton();
    },
    handleListboxKeyDown(event) {
      if (this.disabled) {
        return;
      }

      const key = event.key;

      if (key === "ArrowUp" || key === "ArrowDown") {
        this.openMenu();
      }

      this.handleKeyDown(event);
    },
    handleListboxOptionKeyDown(event) {
      this.handleKeyDown(event);
    },
    handleKeyDown(event) {
      if (this.disabled) {
        return;
      }

      const key = event.key;

      if (key === "ArrowUp") {
        this.moveToPreviousOption();
      } else if (key === "ArrowDown") {
        this.moveToNextOption();
      } else if (key === " " || key === "Enter") {
        this.selectCurrentlyHighlightedElement();
        this.isOpen = false;
        this.setFocusOnTriggerButton();
      } else if (key === "Escape") {
        this.closeMenu();
        this.setFocusOnTriggerButton();
      }
    },
    onClickOutsideList() {
      this.isOpen = false;
    },
    handleFocusOut(event) {
      this.$nextTick(() => {
        if (!this.$refs.listbox || (!this.$refs.listbox.contains(document.activeElement) && !this.$refs.listbox.contains(event.relatedTarget))) {
          this.closeMenu();
        }
      });
    },
  },
  watch: {
    value() {
      this.initializeActiveOptionIndex();
    },
    isOpen() {
      if (this.isOpen && this.$vssWidth <= 800) {
        // Prevent scrolling the page in the background while scrolling the ListBox on Mobile
        document.documentElement.style.overflow = "hidden";
        return;
      }

      document.documentElement.style.overflow = "auto";
    },
    $vssWidth() {
      // If the user resizes the screen, close the menu so that the scroll behavior of the document is reset.
      if (this.$vssWidth >= 800) {
        this.closeMenu();
      }
    },
  },
};
</script>

<style scoped>
.listbox-trigger-button-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.listbox-trigger-button {
  background-color: #fff;
  line-height: 1.5rem;
}

.listbox-trigger-button-disabled {
  cursor: unset;
  background: rgb(236, 236, 236);
  line-height: 1.5rem;
  color: rgb(105, 105, 105);
  font-size: 14px;
  font-weight: normal;
}

.listbox-trigger-button-focus-outline,
.listbox-trigger-button:active,
.listbox-trigger-button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(87, 213, 249, 0.7);
}

.listbox-trigger-button-chevron {
  color: #008bae;
}

.listbox-trigger-button-chevron-disabled {
  color: #949494;
}

.listbox-list-container {
  z-index: 100;
}

@media screen and (max-width: 800px) {
  .listbox-list-container {
    z-index: 100;
  }
}
</style>
