import { Machine } from "xstate";
import TutorialRedirectUtil from "@/util/TutorialRedirectUtil";

/**
 * State machine that controls the various valid states of the application.
 */
const StateMachine = Machine(
  {
    id: "default",
    initial: "initial",
    states: {
      initial: {
        on: {
          ENTER_TUTORIAL: "tutorial",
        },
      },
      tutorial: {
        initial: "initial",
        states: {
          initial: {
            on: {
              EXIT: "#default.initial",
              SEARCH_FOCUS: "immersiveTutorial",
            },
          },
          immersiveTutorial: {
            initial: "search",
            states: {
              search: {
                on: {
                  EXIT: "#default.initial",
                  DRUG_SELECTION: "formularyComparison",
                },
              },
              formularyComparison: {
                initial: "untrained",
                states: {
                  untrained: {
                    on: {
                      EXIT: "#default.initial",
                      FORMULARY_SELECTION: "trained",
                    },
                  },
                  trained: {
                    entry: "completeTutorial",
                    initial: "firstTime",
                    states: {
                      firstTime: {
                        on: {
                          SHOWN_TRAINED_POPOVER: "secondTime",
                          EXIT: "#default.initial",
                        },
                      },
                      secondTime: {
                        on: {
                          EXIT: "#default.initial",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      completeTutorial: () => {
        TutorialRedirectUtil.markThatUserHasGoneThroughTutorial();
      },
    },
  }
);

export default StateMachine;
