<template>
  <button class="font-normal text-sm leading-6 BaseButton" v-on="$listeners">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    /**
     * The text for the button
     */
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.BaseButton {
  min-height: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  background: var(--BaseButton-BackgroundColor);
  color: var(--BaseButton-Color);
}
</style>
