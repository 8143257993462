<template>
  <div v-if="formularies && formularies.length > 0" class="px-8 pt-8 pb-4 rounded-lg full-drug-lists-download-section">
    <div class="mb-4">
      <h2 class="text-base md:inline mr-6 font-medium leading-normal full-drug-lists-download-header">
        {{ $t("FullDrugListsDownloadSection.Header") }}
      </h2>
    </div>
    <div class="formulary-list">
      <div v-for="(formulary, index) in formularies" :key="formulary.id" class="mb-6 individual-formulary-section">
        <div class="flex justify-center items-center">
          <a :href="formulary.downloadUrl" :aria-labelledby="`formulary-download-link-${index}`" download target="_blank">
            <Icon name="arrow-to-bottom" class="drug-list-download-icon" />
          </a>
        </div>
        <div>
          <a :href="formulary.downloadUrl" download target="_blank">
            <span class="font-bold leading-6 mr-4 formulary-download-link" :id="`formulary-download-link-${index}`">
              {{ formulary.productScheduleDescription }}
            </span>
          </a>
          <span class="leading-6 download-size-label" v-if="formulary.fileSize">
            {{ formulary.fileSize }}
          </span>
        </div>
        <p v-if="formulary.description" class="text-sm leading-6 formulary-description">
          {{ formulary.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Icon from "@/components/Icon";

export default {
  name: "FullDrugListsDownloadSection",
  components: { Icon },
  computed: mapState(["formularies"]),
  methods: mapActions(["makeRequestToGetFormularies"]),
  created() {
    this.makeRequestToGetFormularies();
  },
};
</script>

<style scoped>
.full-drug-lists-download-section {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border-color: rgba(0, 0, 0, 0.16);
  color: rgb(51, 51, 51);
}

.full-drug-lists-download-header {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  color: rgb(26, 26, 26);
}

.formulary-list {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
}

.individual-formulary-section {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 8px;
}

.formulary-download-link,
.drug-list-download-icon {
  font-family: Roboto-Bold, sans-serif;
  color: var(--BaseLink-Color);
}

.download-size-label {
  font-family: Roboto-Italic, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  color: #5e5e5e;
}

.formulary-description {
  color: rgb(51, 51, 51);
  grid-column: 2;
}

@media screen and (min-width: 800px) {
  .formulary-list {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
  }
}
</style>
