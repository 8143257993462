/**
 * Utility function for putting the formularies into a certain order.
 *
 * @param {Object[]} formularies  - A list of formulary objects from the formularies endpoint response.
 * @param {String[]} formularySortOrderPreference - An array of numbers corresponding to the id of the formulary. These
 *    should be listed in the order you want the formularies to be returned in. If any formulary is not specified in
 *    this array, it will be placed at the end.
 *
 * @returns {Object[]} A new copy of the formularies that have been sorted.
 */
export default function sortFormulariesBasedOnClientPreferences(formularies, formularySortOrderPreference) {
  const originalFormularies = [...formularies];
  const sortedFormularies = [];

  formularySortOrderPreference.forEach((formularyDescription) => {
    const index = originalFormularies.findIndex((formulary) => formulary.productScheduleDescription === formularyDescription);

    if (index !== -1) {
      sortedFormularies.push(originalFormularies[index]);
      originalFormularies.splice(index, 1);
    }
  });

  // Put any non-specified formularies at the end
  return [...sortedFormularies, ...originalFormularies];
}
