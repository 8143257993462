/**
 * Function for determining which client should be used when retrieving data from the backend.
 *
 * @param url - The url to base the key off of. This parameter is only exposed for testing purposes; the default
 *              value automatically handles retrieving it based on the browser.
 *
 * @returns {String} String representing the client whitelabel to use.
 */
export default function getAdjudifyApiEndpointClientPrefix() {
  return "BlueTN";
}
