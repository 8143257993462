import axios from "axios";
import { adjudifyAxios } from "@/util/setupAdjudifyAxios";
import getApiEndpointClientPrefix from "@/util/getApiEndpointClientPrefix";
import getAdjudifyApiEndpointClientPrefix from "@/util/getAdjudifyApiEndpointClientPrefix";

/**
 * Utilities for retrieving information related to medication.
 */
const MedicationCoverageService = {
  async getMedication({ id, date } = {}) {
    const adjudiliteClient = getApiEndpointClientPrefix();
    const response = await axios.get(`/medications/${id}/medication-coverage`, {
      params: {
        client: adjudiliteClient,
      },
    });

    const adjudifyClient = getAdjudifyApiEndpointClientPrefix();
    const formularyCoverage = await adjudifyAxios
      .get(`/adjudify/tiercheck/formularyCoverage`, {
        params: { client: adjudifyClient, fltMedicationId: id, effectiveDate: date },
      })
      .then((response) => response.data);

    response.data.data.coverageInfo = formularyCoverage;

    if (response.data.data.relatedResults.length > 0) {
      for (const relatedResult of response.data.data.relatedResults) {
        const relatedResultCoverageInfo = await adjudifyAxios.get(`/adjudify/tiercheck/formularyCoverage`, {
          params: {
            client: adjudifyClient,
            fltMedicationId: relatedResult.id,
            effectiveDate: date,
          },
        });
        relatedResult.coverageInfo = relatedResultCoverageInfo.data;
      }
    }

    return response.data.data;
  },
};

export default MedicationCoverageService;
