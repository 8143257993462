/**
 * Returns a formatted string to display the related results of a medication. This function uses the i18n utilities,
 * and therefore should only be used when bound to a Vue component.
 *
 *
 *
 * @returns {boolean} - A formatted i18n string.
 */
import moment from "moment";
import getWhiteLabelPrefix from "@/util/getWhiteLabelPrefix";

const planYear = {
  THIS_YEAR: getCurrentDate().year(),
  MULTI_YEAR: getMultiPlanYearDate().year(),
};

const planYearDate = {
  THIS_PLAN_YEAR_DATE: getCurrentDate().format("YYYY-MM-DD"),
  MULTI_PLAN_YEAR_DATE: getMultiPlanYearDate().format("YYYY-MM-DD"),
};

function getCurrentDate() {
  return moment();
}

function getMultiPlanYearDate() {
  if (getCurrentDate().isAfter(getCurrentDate().set({ month: 5, date: 1 }))) {
    return getCurrentDate().add(1, "year").startOf("year");
  } else {
    return getCurrentDate().subtract(1, "year").endOf("year");
  }
}

function isMultiYearEnabled() {
  const clientPrefix = getWhiteLabelPrefix();
  const whiteLabelSettings = require("@/components/WhiteLabelProvider/clients/" + clientPrefix + "/Settings.json");

  return whiteLabelSettings?.isMultiYearEnabled;
}

export { isMultiYearEnabled, planYear, planYearDate };
