// Polyfills for IE 11 Support
import "core-js/modules/es.promise.all-settled";

import Vue from "vue";
import LogRocket from "logrocket";
import { v4 as uuid } from "uuid";
import { Datacenter, datadogRum } from "@datadog/browser-rum";
import { loadPendo } from "./pendo";

import "@/assets/styles";
import App from "./App.vue";
import store from "@/store/store";
import router from "./router";
import i18n from "./i18n";
import attachAllFilters from "@/util/VueFilters";
import setupAxios from "@/util/setupAxios";
import setupAdjudifyAxios from "@/util/setupAdjudifyAxios";
import getApiEndpointClientPrefix from "@/util/getApiEndpointClientPrefix";

Vue.config.productionTip = false;
setupAxios(process.env.NODE_ENV);
setupAdjudifyAxios(process.env.NODE_ENV);
attachAllFilters(Vue);

function renderVueApp() {
  new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

if (process.env.NODE_ENV === "development") {
  // If running locally, proxy all fetch request to local MirageJS server
  const getServerUtility = () => import("./server");
  getServerUtility().then(({ makeServer }) => {
    makeServer();
    renderVueApp();
  });
}
// E2E tests in external project should hit actual backend database; localhost is only used for this project's Cypress
// tests
else if (window.Cypress && window.location.href.includes("localhost")) {
  // If running Cypress tests, proxy all fetch requests to Cypress's MirageJS server
  const getMirage = () => import("miragejs");

  getMirage().then(({ Server, Response }) => {
    new Server({
      environment: "test",
      routes() {
        let methods = ["get", "put", "patch", "post", "delete"];
        methods.forEach((method) => {
          this[method]("/*", async (schema, request) => {
            let [status, headers, body] = await window.handleFromCypress(
              request
            );
            return new Response(status, headers, body);
          });
        });
      },
    });
    renderVueApp();
  });
} else {
  const userId = uuid();
  LogRocket.init("vj7fnr/tier-check");
  LogRocket.identify(userId, {
    clientPrefix: getApiEndpointClientPrefix(),
  });

  // By default, LogRocket doesn't captured unhandled promises. Add this in to log those.
  // For more details, see the link below.
  // https://github.com/LogRocket/logrocket/issues/11
  window.addEventListener("unhandledrejection", (event) => {
    LogRocket.captureException(event.reason);
  });

  if (
    process.env.VUE_APP_DATADOG_APPLICATION_ID &&
    process.env.VUE_APP_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      datacenter: Datacenter.US,
      sampleRate: 100,
      trackInteractions: true,
    });
  }

  if (process.env.VUE_APP_PENDO_APPLICATION_ID) {
    loadPendo(userId);
  }

  renderVueApp();
}
