<template>
  <component :is="pageLayoutComponent">
    <div>
      <slot></slot>
    </div>
  </component>
</template>
<script>
/**
 * Component that handles switching anything required for white labeling.
 */
import DefaultWhiteLabelHandler from "@/components/WhiteLabelProvider/DefaultWhiteLabelHandler";
import WhiteLabelSettingsMixin from "@/components/WhiteLabelProvider/WhiteLabelSettingsMixin";
import getClientWhiteLabelComponent from "@/components/WhiteLabelProvider/getClientWhiteLabelComponent";

export default {
  name: "WhiteLabelProvider",
  mixins: [WhiteLabelSettingsMixin],
  props: {
    clientPrefix: {
      type: String,
      default: null,
    },
  },
  computed: {
    pageLayoutComponent() {
      if (this.clientPrefix) {
        return getClientWhiteLabelComponent(this.clientPrefix);
      }

      return DefaultWhiteLabelHandler;
    },
  },
};
</script>
