<template>
  <font-awesome-icon :icon="[type, name]" :size="size" :spin="spin" />
</template>

<script>
/**
 * Wrapper for third party icon components to make it easier to change icon libraries.
 */
export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, null],
      default: null,
      validator(input) {
        const validValues = ["xs", "sm", "lg", "2x", "3x", "5x", "7x", "10x"];
        return validValues.indexOf(input) !== -1;
      },
    },
    /**
     * The type of icon to pull from. Values include...
     * - "fas": The solid icon
     * - "fal": The light-weight icons
     * - "far": The regular icons
     */
    type: {
      type: String,
      default: "fas",
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
