import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store/store";
import Home from "@/views/Home.vue";
const BrowseByNameOrClass = () => import("@/views/BrowseByNameOrClass");
const DrugClassList = () => import("@/views/DrugClassList");
const DrugNameList = () => import("@/views/DrugNameList");
const DrugClassListing = () => import("@/views/DrugClassListing");
const Tutorial = () => import("@/views/Tutorial");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/browse",
    name: "Browse drugs by name or class",
    component: BrowseByNameOrClass,
    props: true,
    children: [
      {
        path: "drug-class",
        component: DrugClassList,
      },
      {
        path: "drug-name",
        component: DrugNameList,
      },
    ],
  },
  {
    path: "/browse/drug-class/:id",
    name: "Drug Class Listing",
    component: DrugClassListing,
    props: true,
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: Tutorial,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // As we navigate between routes, clear out any existing toasts
  Store.dispatch("Toasts/clearAllToasts");
  next();
});

export default router;
