export default function compareDoses(left, right) {
  const matchNumbers = /([0-9]+\.?[0-9]*)/g;
  const leftDoses = left.match(matchNumbers);
  const rightDoses = right.match(matchNumbers);

  // Handle if doses contain strings (sort string doses after numerical doses)
  if (leftDoses == null && rightDoses == null) {
    return left.localeCompare(right);
  } else if (leftDoses == null) {
    return 1;
  } else if (rightDoses == null) {
    return -1;
  }

  for (let i = 0; i < leftDoses.length && i < rightDoses.length; i++) {
    let difference = leftDoses[i] - rightDoses[i];
    if (difference !== 0) {
      return difference;
    }
  }
  // If all available doses match, default to the dose with fewest values
  return leftDoses.length - rightDoses.length;
}
