<template>
  <DefaultPageLayout data-testid="DefaultWhiteLabelHandler">
    <template slot="header">
      <nav
        class="flex items-center justify-between flex-wrap bg-white py-4 px-6 md:py-8 md:px-24 rx-savings-header"
        data-testid="page-header"
      >
        <router-link :to="{ name: 'Home' }" class="mr-6">
          <img
            :src="logo"
            alt="Logo for Rx Savings Solutions"
            class="rx-savings-header-logo"
          />
        </router-link>
        <a
          href="https://portal.rxsavingssolutions.com/"
          class="leading-6 sign-into-rx-savings-link"
          ><span class="mr-2 text-sm">{{
            $t("SignIntoRxSavingsCard.SignInToRxLink")
          }}</span
          ><Icon type="far" name="external-link-square-alt"
        /></a>
      </nav>
    </template>
    <template slot="content">
      <slot />
    </template>
  </DefaultPageLayout>
</template>

<script>
import "./DefaultWhiteLabelStyles.css";
import DefaultPageLayout from "@/components/WhiteLabelProvider/DefaultPageLayout";
import Icon from "@/components/Icon";
import Logo from "@/assets/logo.svg";

/**
 * Provides the default header and footer if a client does not have a need for white labeling.
 */
export default {
  name: "DefaultWhiteLabelHandler",
  components: { DefaultPageLayout, Icon },
  computed: {
    logo() {
      return Logo;
    },
  },
};
</script>

<style scoped>
.rx-savings-header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.sign-into-rx-savings-link {
  color: rgb(0, 128, 164);
}

.rx-savings-header-logo {
  width: 97px;
}

@media screen and (min-width: 800px) {
  .rx-savings-header-logo {
    height: 40px;
    width: 161px;
  }
}
</style>
