import isLocalStorageAvailable from "@/util/isLocalStorageAvailable";

const HAS_USER_DONE_TUTORIAL_KEY = "hasUserDoneTutorial";
const COMPLETED_INDICATOR = "yes";

function shouldUserRedirectToTutorial() {
  // If we cannot detect whether or not someone has already done the tutorial, go ahead and let them use the main tool
  if (!isLocalStorageAvailable()) {
    return false;
  }

  return (
    localStorage.getItem(HAS_USER_DONE_TUTORIAL_KEY) !== COMPLETED_INDICATOR
  );
}

function markThatUserHasGoneThroughTutorial() {
  localStorage.setItem(HAS_USER_DONE_TUTORIAL_KEY, COMPLETED_INDICATOR);
}

export default {
  shouldUserRedirectToTutorial,
  markThatUserHasGoneThroughTutorial,
};
