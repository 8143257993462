/**
 * State reducer pattern for managing the current state of a data table and an event that occurs in the medication data
 * table. The functionality here assumes the generic state reducer has run already.
 */
const DataTableStateReducer = {
  /**
   * Default reducer function for getting new state based on a column requesting that its sorting behavior changes.
   *
   * @param {Object} state - Object containing the following properties:
   *   - headers: Array of {@link HeaderConfiguration}
   *   - data: Array of objects representing the data of the data table.
   * @param {Object} event - Object containing the following properties:
   *   - header: The data of the header that triggered the event
   * @param {Number} selectedFormularyId - The id of the formulary that is currently active
   * @param selectedFormIndexes - Object whose keys are the id's of the medications and whose values are the indexes of
   *    of the form currently selected.
   * @param selectedDoseIndexes - Object whose keys are the id's of the medications and whose values are the indexes of
   *    of the form currently selected.
   *@param formsAndDoses - Object whose keys are the id's of the medications and list the forms and the corresponding dose
   *    and medicationDetailsId.
   *
   * @returns {Object} A new copy of the state object with properties reflecting changes based on the event.
   */
  handleAverageCostColumnSortingChange(
    state,
    event,
    selectedFormularyId,
    selectedFormIndexes,
    selectedDoseIndexes,
    formsAndDoses
  ) {
    const { sortDirection } = state.headers.find(
      (header) => header.id === event.header.id
    );

    const updatedData = [...state.data].sort((dataRow1, dataRow2) => {
      const resultBasedOnSortDirection = sortDirection === "ascending" ? 1 : -1;

      const price1 = getSelectedMedicationDetails(
        dataRow1,
        selectedFormularyId,
        selectedFormIndexes[dataRow1.id],
        selectedDoseIndexes[dataRow1.id],
        formsAndDoses
      )?.averageCost;

      const price2 = getSelectedMedicationDetails(
        dataRow2,
        selectedFormularyId,
        selectedFormIndexes[dataRow2.id],
        selectedDoseIndexes[dataRow2.id],
        formsAndDoses
      )?.averageCost;

      return defaultComparator(price1, price2, resultBasedOnSortDirection);
    });

    return {
      ...state,
      data: updatedData,
    };
  },

  /**
   * Default reducer function for getting new state based on a column requesting that its sorting behavior changes.
   *
   * @param {Object} state - Object containing the following properties:
   *   - headers: Array of {@link HeaderConfiguration}
   *   - data: Array of objects representing the data of the data table.
   * @param {Object} event - Object containing the following properties:
   *   - header: The data of the header that triggered the event
   * @param {Number} selectedFormularyId - The id of the formulary that is currently active
   * @param selectedFormIndexes - Object whose keys are the id's of the medications and whose values are the indexes of
   *    of the form currently selected.
   * @param selectedDoseIndexes - Object whose keys are the id's of the medications and whose values are the indexes of
   *    of the form currently selected.
   * @param formsAndDoses - Object whose keys are the id's of the medications and list the forms and the corresponding dose
   *    and medicationDetailsId.
   *
   * @returns {Object} A new copy of the state object with properties reflecting changes based on the event.
   */
  handleDrugTierColumnSortingChange(
    state,
    event,
    selectedFormularyId,
    selectedFormIndexes,
    selectedDoseIndexes,
    formsAndDoses
  ) {
    const { sortDirection } = state.headers.find(
      (header) => header.id === event.header.id
    );

    const updatedData = [...state.data].sort((dataRow1, dataRow2) => {
      const resultBasedOnSortDirection = sortDirection === "ascending" ? 1 : -1;

      const tier1 = getSelectedCoverage(
        dataRow1,
        selectedFormularyId,
        selectedFormIndexes[dataRow1.id],
        selectedDoseIndexes[dataRow1.id],
        formsAndDoses
      )?.coverageTier?.tier;

      const tier2 = getSelectedCoverage(
        dataRow2,
        selectedFormularyId,
        selectedFormIndexes[dataRow2.id],
        selectedDoseIndexes[dataRow2.id],
        formsAndDoses
      )?.coverageTier?.tier;

      return defaultComparator(tier1, tier2, resultBasedOnSortDirection);
    });

    return {
      ...state,
      data: updatedData,
    };
  },
};

function defaultComparator(property1, property2, indexBasedOnSortDirection) {
  if (property1 === undefined) {
    return indexBasedOnSortDirection;
  }

  if (property2 === undefined) {
    return indexBasedOnSortDirection * -1;
  }

  if (property1 < property2) {
    return indexBasedOnSortDirection;
  } else if (property1 === property2) {
    return 0;
  } else {
    return indexBasedOnSortDirection * -1;
  }
}

function getSelectedCoverage(
  medication,
  selectedFormularyId,
  selectedFormIndex,
  selectedDoseIndex,
  formsAndDoses
) {
  const coverageInfo = medication.coverageInfo.find(
    (info) => info.productScheduleId === selectedFormularyId
  );

  const dose =
    formsAndDoses[medication.id][selectedFormularyId][selectedFormIndex].doses[
      selectedDoseIndex
    ];

  return coverageInfo?.coverages.find((coverage) => {
    return coverage.medispanDrugDescriptorId === dose.medispanDrugDescriptorId;
  });
}

function getSelectedMedicationDetails(
  medication,
  selectedFormularyId,
  selectedFormIndex,
  selectedDoseIndex,
  formsAndDoses
) {
  const dose =
    formsAndDoses[medication.id][selectedFormularyId][selectedFormIndex].doses[
      selectedDoseIndex
    ];

  const medicationNotCovered = getSelectedCoverage(
    medication,
    selectedFormularyId,
    selectedFormIndex,
    selectedDoseIndex,
    formsAndDoses
  ).coverageAlerts.some(
    (coverageAlert) => coverageAlert.durServiceDescription === "Not Covered"
  );

  const medicationDetails = medication.medicationDetails.find(
    (medicationDetail) => {
      return (
        dose.medispanDrugDescriptorId ===
        medicationDetail.medispanDrugDescriptorId
      );
    }
  );

  if (medicationNotCovered) {
    medicationDetails.averageCost = null;
  }
  return medicationDetails;
}

export default DataTableStateReducer;
