/**
 * Utility for "backfilling" any missing coverage information. For example, if there are 3 supported formularies, but
 * the first drug that is searched for only provides coverage information for the first formulary, this function will
 * append enough information to show the medication as "Not Covered" on the other two. This copied information will
 * still retain "medicine-level" details; details such as GPI14.
 *
 * @param {Object} medicationCoverageResponse - The data portion of the response from the medication coverage API
 *    endpoint.
 * @param {List<Object>} [formularies] - A list of formulary objects. Each object should have a name and id property.
 *    This list could be empty or undefined; in this scenario, the method will just return the original coverage
 *    response.
 *
 * @returns {Object} An updated copy of the coverage response.
 */
export default function sanitizeMedicationCoverageResponse(medicationCoverageResponse, formularies) {
  if (!formularies || formularies.length === 0) {
    return medicationCoverageResponse;
  }

  const updatedResponse = clone(medicationCoverageResponse);
  updatedResponse.coverageInfo = getUpdatedCoverageInfo(updatedResponse.coverageInfo, formularies);

  updatedResponse.coverageInfo = addUncoveredFormsAndDosesCoverageInfo(updatedResponse);

  if (!updatedResponse.isGeneric && updatedResponse.relatedResults?.length > 0) {
    updatedResponse.relatedResults[0].coverageInfo = getUpdatedCoverageInfo(updatedResponse.relatedResults[0].coverageInfo, formularies);
  }

  return updatedResponse;
}

function getUpdatedCoverageInfo(coverageInfo, formularies) {
  if (!coverageInfo || coverageInfo.length === 0) {
    return coverageInfo;
  }

  const coverageInfoFormularyIds = coverageInfo.map((info) => info.productScheduleId);

  const uncoveredFormularyIds = formularies
    .map((formulary) => formulary.productScheduleId)
    .filter((formularyId) => !coverageInfoFormularyIds.includes(formularyId));

  const updatedCoverageInfo = clone(coverageInfo);
  uncoveredFormularyIds.forEach((formularyId) => {
    const coverageInfoEntry = getEmptyCopyOfCoverageInfoEntry(coverageInfo[0], formularyId);
    updatedCoverageInfo.push(coverageInfoEntry);
  });

  return updatedCoverageInfo;
}
function addUncoveredFormsAndDosesCoverageInfo(updatedResponse) {
  // Check if there is coverage information present
  if (!updatedResponse.coverageInfo || updatedResponse.coverageInfo.length === 0) {
    return updatedResponse.coverageInfo;
  }

  // Get all medication details
  const allMedicationDetailIds = updatedResponse.medicationDetails.map((details) => details.medispanDrugDescriptorId);

  const updatedCoverageInfo = clone(updatedResponse.coverageInfo);
  updatedCoverageInfo.forEach((info) => {
    const coveredMedicationDetailIds = info.coverages.map((coverage) => coverage.medispanDrugDescriptorId);
    const uncoveredMedicationIds = allMedicationDetailIds.filter((id) => !coveredMedicationDetailIds.includes(id));

    // Add uncovered forms and doses to coverage information
    uncoveredMedicationIds.forEach((id) => {
      const medicationDetail = updatedResponse.medicationDetails.find((detail) => detail.medispanDrugDescriptorId === id);
      info.coverages.push(getEmptyCoverageInfoEntryForFormsAndDoses(info.coverages[0], medicationDetail));
    });
  });
  return updatedCoverageInfo;
}
function getEmptyCoverageInfoEntryForFormsAndDoses(coverageEntry, medicationDetail) {
  const updatedCoverageEntry = clone(coverageEntry);

  updatedCoverageEntry.form = medicationDetail.form;
  updatedCoverageEntry.strength = medicationDetail.dose;
  updatedCoverageEntry.medispanDrugDescriptorId = medicationDetail.medispanDrugDescriptorId;
  updatedCoverageEntry.gpi = medicationDetail.gpi14;
  updatedCoverageEntry.coverageTier = {
    tier: "",
  };
  updatedCoverageEntry.averageCost = null;
  updatedCoverageEntry.coverageAlerts = [
    {
      durServiceDescription: "Not Covered",
      message: "This medication at this form and dosage is not covered under this formulary.",
    },
  ];

  return updatedCoverageEntry;
}

function getEmptyCopyOfCoverageInfoEntry(coverageInfoEntry, formularyId) {
  const updatedCoverageInfoEntry = clone(coverageInfoEntry);

  updatedCoverageInfoEntry.productScheduleId = formularyId;
  updatedCoverageInfoEntry.coverages = updatedCoverageInfoEntry.coverages.map((coverageEntry) => ({
    ...coverageEntry,
    coverageTier: {
      tier: "",
    },
    averageCost: null,
    coverageAlerts: [
      {
        durServiceDescription: "Not Covered",
        message: "This medication at this form and dosage is not covered under this formulary.",
      },
    ],
  }));

  return updatedCoverageInfoEntry;
}

/**
 * Small utility to clone an object. This is a naive-copy and should only be used for JSON serializable data.
 *
 * @param {Object} object - An object to copy
 *
 * @returns {Object} A copy of the object provided.
 */
function clone(object) {
  return JSON.parse(JSON.stringify(object));
}
