import axios from "axios";
import getApiEndpointClientPrefix from "@/util/getApiEndpointClientPrefix";

/**
 * Utilities for retrieving information related to medication.
 */
const MedicationsService = {
  Constants: {
    SortBy: {
      RELEVANCE: "RELEVANCE",
      ALPHANUMERIC: "ALPHANUMERIC",
    },
  },

  async getMedications({
    pageSize,
    pageNumber,
    name,
    sortBy,
    prefixLetter,
    classId,
    frequentForClient,
  } = {}) {
    const response = await axios.get("/medications", {
      params: {
        pageSize,
        pageNumber,
        name,
        sortBy,
        prefixLetter,
        classId,
        frequentForClient,
      },
    });

    return response.data;
  },

  async getFrequentMedications({ pageSize = 5 } = {}) {
    const client = getApiEndpointClientPrefix();
    const response = await axios.get("/medications/frequent", {
      params: {
        client,
        pageSize,
      },
    });

    return response.data.data;
  },
};

export default MedicationsService;
