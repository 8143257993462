<template>
  <div>
    <div
      class="grid sm:grid-cols-1 md:grid-cols-4 mb-10"
      v-if="WhiteLabelSettings.showFormularyDownloadSection || WhiteLabelSettings.showSignIntoRxSavingsSolutionsCard"
      data-testid="page-footer"
    >
      <FullDrugListsDownloadSection
        data-testid="formulary-download-section"
        class="mb-8 md:mb-0 md:col-span-3 md:mr-6"
        v-if="WhiteLabelSettings.showFormularyDownloadSection"
        :class="{
          'md:col-span-4': !WhiteLabelSettings.showSignIntoRxSavingsSolutionsCard,
        }"
      />
      <SignIntoRxSavingsCard
        data-testid="sign-into-rx-savings-card"
        v-if="WhiteLabelSettings.showSignIntoRxSavingsSolutionsCard"
        :class="{
          'md:col-span-4': !WhiteLabelSettings.showFormularyDownloadSection,
        }"
      />
    </div>
    <SelfHostedFormularyUrlCard
      data-testid="self-hosted-formulary-url-card"
      v-if="!isMedicareFormularySelected && WhiteLabelSettings.selfHostedFormularyUrl"
      :self-hosted-formulary-url="WhiteLabelSettings.selfHostedFormularyUrl"
      class="mb-8"
    />
    <SelfHostedFormularyUrlCard
      data-testid="self-hosted-blue-advantage-formulary-url-card"
      v-if="(isBlueAdvantageFormularySelected || isBlueAdvantageExtraFormularySelected) && WhiteLabelSettings.selfHostedBlueAdvantageFormularyUrl"
      :self-hosted-formulary-url="WhiteLabelSettings.selfHostedBlueAdvantageFormularyUrl"
      class="mb-8"
    />
    <SelfHostedFormularyUrlCard
      data-testid="self-hosted-blue-care-plus-formulary-url-card"
      v-if="isBlueCarePlusFormularySelected && WhiteLabelSettings.selfHostedBlueCarePlusFormularyUrl"
      :self-hosted-formulary-url="WhiteLabelSettings.selfHostedBlueCarePlusFormularyUrl"
      class="mb-8"
    />
    <RxSavingsFooter />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SignIntoRxSavingsCard from "@/components/SignIntoRxSavingsCard";
import FullDrugListsDownloadSection from "@/components/FullDrugListsDownloadSection";
import SelfHostedFormularyUrlCard from "@/components/SelfHostedFormularyUrlCard";
import RxSavingsFooter from "@/components/RxSavingsFooter";
import { isBlueAdvantageExtraFormulary, isBlueAdvantageFormulary, isBlueCarePlusFormulary, isMedicareFormulary } from "@/util/isMedicareFormulary";

/**
 * Generic page footer that includes the formularies download section, the sign in to Rxss card, and the links to our
 * public site.
 */
export default {
  name: "PageFooter",
  components: {
    SignIntoRxSavingsCard,
    FullDrugListsDownloadSection,
    SelfHostedFormularyUrlCard,
    RxSavingsFooter,
  },
  inject: ["WhiteLabelSettings"],
  computed: {
    ...mapState(["formularies", "selectedFormularyIndex"]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formulariesByPlanYear() {
      return this.formularies ? this.getFormulariesByPlanYear() : [];
    },
    selectedFormularyName() {
      return this.formulariesByPlanYear[this.selectedFormularyIndex]?.productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageFormularySelected() {
      return isBlueAdvantageFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageExtraFormularySelected() {
      return isBlueAdvantageExtraFormulary(this.selectedFormularyName);
    },
    isBlueCarePlusFormularySelected() {
      return isBlueCarePlusFormulary(this.selectedFormularyName);
    },
  },
};
</script>

<style scoped></style>
