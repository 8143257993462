<template>
  <div>
    <div
      role="tooltip"
      ref="tooltip"
      class="m-4 leading-normal text-base whitespace-normal"
    >
      <div class="text-right pb-1">
        <button @click="hidePopover" data-testId="popover-close">
          <Icon name="times" type="fal" size="lg" />
        </button>
      </div>
      <div class="text-left">
        <slot name="tooltip"></slot>
      </div>
    </div>
    <div ref="trigger" aria-describedby="tooltip" class="inline-block">
      <slot name="tooltip-trigger"></slot>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import { hideAll } from "tippy.js";
import Icon from "@/components/Icon";

/**
 * Popover component
 */
export default {
  name: "Popover",
  components: { Icon },
  props: {
    /**
     * Defines whether or not the consumer wants to be complete control over the visibility of the popover. This
     * can be done through the methods defined on the component.
     */
    isControlledPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tippyInstance: undefined,
    };
  },
  methods: {
    hidePopover() {
      hideAll();
    },
    showPopover() {
      this.tippyInstance.show();
    },
  },
  mounted() {
    let template = this.$refs["tooltip"];
    let popoverRef = this.$refs["trigger"];

    this.tippyInstance = tippy(popoverRef, {
      theme: "formulary",
      interactive: true,
      trigger: this.isControlledPopover ? "manual" : "click",
      content: template,
      allowHTML: true,
      hideOnClick: true,
      placement: "top-start",
      zIndex: 40,
    });
  },
};
</script>

<style>
.tippy-content {
  padding: unset;
}

.tippy-box[data-theme~="formulary"] {
  border: 1px solid rgb(94, 94, 94);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 8px;
  color: #424242;
}

.tippy-box[data-theme~="formulary"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: #ffffff;
}
.tippy-box[data-theme~="formulary"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: #ffffff;
}
.tippy-box[data-theme~="formulary"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: #ffffff;
}
.tippy-box[data-theme~="formulary"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: #ffffff;
}

/* Updates to the style of the arrow */

.tippy-box {
  border: 1px transparent;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:after {
  border-top-color: #000000;
  border-width: 8px 8px 0;
  bottom: -8px;
  left: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:after {
  border-bottom-color: #000000;
  border-width: 0 8px 8px;
  top: -8px;
  left: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:after {
  border-left-color: #000000;
  border-width: 8px 0 8px 8px;
  right: -8px;
  top: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:after {
  border-width: 8px 8px 8px 0;
  left: -8px;
  top: 0;
  border-right-color: #000000;
}
.tippy-box[data-placement^="top"]
  > .tippy-svg-arrow
  > svg:first-child:not(:last-child) {
  top: 17px;
}
.tippy-box[data-placement^="bottom"]
  > .tippy-svg-arrow
  > svg:first-child:not(:last-child) {
  bottom: 17px;
}
.tippy-box[data-placement^="left"]
  > .tippy-svg-arrow
  > svg:first-child:not(:last-child) {
  left: 12px;
}
.tippy-box[data-placement^="right"]
  > .tippy-svg-arrow
  > svg:first-child:not(:last-child) {
  right: 12px;
}
.tippy-arrow {
  border-color: rgb(94, 94, 94);
}
.tippy-arrow:after {
  content: "";
  z-index: -1;
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

@media only screen and (max-width: 600px) {
  .tippy-box {
    max-width: 90%;
  }
}

/*
Tippy CSS File
 */
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
</style>
