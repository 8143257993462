<template>
  <article class="py-6 px-4 w-full rounded-lg border-solid border medication-card" data-testid="medication-card">
    <div class="mb-2 flex flex-row">
      <NotCoveredTag v-if="!isCovered" class="mr-2" />
      <GenericMedicationTag v-if="isGeneric" />
    </div>
    <div class="mb-4">
      <p class="text-base font-medium text-lg leading-6 medication-name-label">
        {{ medication.name }}
      </p>
      <p class="text-sm font-normal leading-6">{{ relatedListLabel }}</p>
    </div>
    <div v-if="isCovered">
      <CompareMedicarePlans v-if="isMedicareFormularySelected" :medication="medication" class="mb-5 ml-1 mr-1" />
      <div class="flex flex-row">
        <span
          v-if="!isMedicareFormularySelected && averageCost"
          class="text-sm font-bold flex-grow my-auto leading-6 average-cost-field"
          data-testid="medication-average-cost"
        >
          {{ $t("MedicationCard.AverageCostLabel", [averageCost]) }}
        </span>
        <span v-if="tier" class="text-sm text-right leading-6 my-auto">
          <DrugTier :coverageInfo="selectedCoverage" class="inline" />
        </span>
      </div>
      <div v-if="!isMedicareFormularySelected && averageCost" class="mb-5 mt-1">
        <Popover>
          <template slot="tooltip-trigger">
            <button class="text-sm link-color leading-6">
              {{ $t("MedicationCard.AverageCostLink") }}
            </button>
          </template>
          <template slot="tooltip">
            <div class="font-normal leading-normal mb-2">
              {{ $t("MedicationCard.AverageCostPopover") }}
            </div>
          </template>
        </Popover>
      </div>
    </div>
    <div class="mb-4" v-if="!isCovered || (!isMedicareFormularySelected && !averageCost)">
      <i18n tag="span" path="MedicationCard.AverageCostLabel" for="MedicationCard.PricingNotAvailable">
        <span class="pricing-not-available average-cost-field">{{ $t("MedicationCard.PricingNotAvailable") }}</span>
      </i18n>
    </div>
    <CompareWithGeneric v-if="!isGeneric" :medicationId="medication.id" class="mb-5 ml-1 mr-1" />
    <div class="mb-4">
      <label class="text-gray-900 font-bold text-sm leading-normal default-form-label" :id="`MedicationCard-Form-Label-${medication.id}`">
        {{ $t("MedicationCard.Form") }}
      </label>
      <ListBox
        data-testid="medication-form-select"
        :value="selectedForm"
        :options="forms"
        :labelled-by="`MedicationCard-Form-Label-${medication.id}`"
        :label="$t('MedicationCard.Form')"
        @input="(value) => onFormSelect(value)"
        should-disable-if-one-option
      />
      <label class="text-gray-darkest font-bold text-sm leading-normal default-form-label" :id="`MedicationCard-Dose-Label-${medication.id}`">
        {{ $t("MedicationCard.Dose") }}
      </label>

      <ListBox
        data-testid="medication-dose-select"
        :value="selectedDose.dose"
        :options="doses"
        :labelled-by="`MedicationCard-Dose-Label-${medication.id}`"
        :label="$t('MedicationCard.Dose')"
        @input="(value) => onDoseSelect(value)"
        should-disable-if-one-option
      />
    </div>
    <div :class="{ 'mb-6': !areThereCoverageAlerts }">
      <label>
        <span class="default-form-label">
          {{ $t("MedicationCard.DrugClass") }}
        </span>
        <router-link
          :to="{
            name: 'Drug Class Listing',
            params: { id: medicationClass.gpi2 },
          }"
          class="block mb-4 leading-normal text-sm drug-class-link"
        >
          {{ medicationClass.className }}
        </router-link>
      </label>
    </div>
    <div class="mb-4" v-if="areThereCoverageAlerts">
      <span class="mb-2 default-form-label">
        {{ $t("MedicationCard.Coverage") }}
      </span>
      <CoverageAlert :coverageInfo="getCoverage" />
    </div>
    <div class="flex justify-center border-t border-solid border-gray-300 pt-3 text-sm">
      <button class="remove-medication-button" @click="onMedicationRemoveClick(medication)" data-testid="medication-remove-button">
        <Icon name="trash" class="mr-2" />
        <span>{{ $t("MedicationCard.Remove") }}</span>
      </button>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Icon from "@/components/Icon";
import { lowPrecisionCurrencyOrNull } from "@/util/VueFilters";
import { getRelatedNamesLabel } from "@/util/getRelatedNamesLabel";
import CompareMedicarePlans from "@/components/CompareMedicarePlans";
import CompareWithGeneric from "@/components/CompareWithGeneric";
import CoverageAlert from "@/components/CoverageAlert";
import DrugTier from "./DrugTier";
import ListBox from "@/components/ListBox/ListBox";
import Popover from "@/components/Popover/Popover";
import NotCoveredTag from "@/components/NotCoveredTag";
import GenericMedicationTag from "@/components/GenericMedicationTag";
import compareDoses from "@/util/compareDoses";
import { isMedicareFormulary } from "@/util/isMedicareFormulary";

export default {
  name: "MedicationCard",
  components: {
    GenericMedicationTag,
    NotCoveredTag,
    ListBox,
    DrugTier,
    Icon,
    CompareMedicarePlans,
    CompareWithGeneric,
    CoverageAlert,
    Popover,
  },
  props: {
    medication: {
      type: Object,
      required: true,
    },
    formsAndDoses: {
      type: Object,
      required: true,
    },
    selectedFormIndex: {
      type: Number,
      required: true,
    },
    selectedDoseIndex: {
      type: Number,
      default: null,
    },
    selectedFormularyId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions(["removeFromCoverageList"]),
    onFormSelect(value) {
      const selectedFormIndex = this.formsAndDoses[this.medication.id][this.selectedFormularyId].findIndex((formAndDose) => formAndDose.form === value);

      this.$emit("form-select", {
        selectedFormIndex,
        medication: this.medication,
      });
    },
    onDoseSelect(value) {
      const selectedDoseIndex = this.formsAndDoses[this.medication.id][this.selectedFormularyId][this.selectedFormIndex].doses.findIndex(
        (dose) => dose.dose === value
      );

      this.$emit("dose-select", {
        selectedDoseIndex,
        medication: this.medication,
      });
    },
    onMedicationRemoveClick(medication) {
      this.removeFromCoverageList({ medication });
    },
  },
  computed: {
    ...mapState(["selectedFormularyIndex"]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formularies() {
      return this.getFormulariesByPlanYear();
    },
    selectedFormularyName() {
      return this.formularies[this.selectedFormularyIndex].productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
    forms() {
      return this.formsAndDoses[this.medication.id][this.selectedFormularyId].map((forms) => forms.form);
    },
    doses() {
      const doses = this.formsAndDoses[this.medication.id][this.selectedFormularyId][this.selectedFormIndex].doses.map((dose) => dose.dose);
      return doses.sort(compareDoses);
    },
    selectedForm() {
      return this.formsAndDoses[this.medication.id][this.selectedFormularyId][this.selectedFormIndex].form;
    },
    selectedDose() {
      return this.formsAndDoses[this.medication.id][this.selectedFormularyId][this.selectedFormIndex].doses[this.selectedDoseIndex];
    },
    selectedCoverageInfo() {
      return this.medication.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });
    },
    selectedCoverage() {
      if (!this.selectedDose || !this.selectedForm) {
        return undefined;
      }

      return this?.selectedCoverageInfo?.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === this.selectedDose.medispanDrugDescriptorId;
      });
    },
    selectedMedicationDetails() {
      return this.medication.medicationDetails.find((medicationDetail) => {
        return this.selectedDose.medispanDrugDescriptorId === medicationDetail.medispanDrugDescriptorId;
      });
    },
    hasNotCoveredCoverageAlert() {
      return this?.selectedCoverage?.coverageAlerts?.some((coverageAlert) => coverageAlert.durServiceDescription === "Not Covered");
    },
    areThereCoverageAlerts() {
      return !this.selectedCoverage || this?.selectedCoverage?.coverageAlerts?.length > 0;
    },
    relatedListLabel() {
      return getRelatedNamesLabel.call(this, this.medication);
    },
    averageCost() {
      return lowPrecisionCurrencyOrNull(this?.selectedMedicationDetails?.averageCost);
    },
    tier() {
      return this?.selectedCoverage?.coverageTier.tier;
    },
    isCovered() {
      return this.tier && !this.hasNotCoveredCoverageAlert;
    },
    isGeneric() {
      return this.medication.isGeneric;
    },
    getCoverage() {
      return this.selectedCoverage?.coverageAlerts;
    },
    medicationClass() {
      const medicationDetails = this.selectedMedicationDetails;

      return this.medication.medicationClasses.find((medicationClass) => {
        return medicationClass.gpi2 === medicationDetails.classId;
      });
    },
  },
};
</script>

<style scoped>
.medication-card {
  border-color: var(--BaseCard-BorderColor);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.default-form-label {
  font-family: Roboto-Bold, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.medication-name-label {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.average-cost-field {
  font-family: Roboto-Bold, sans-serif;
}

.pricing-not-available {
  color: rgb(101, 33, 0);
}

.drug-class-link {
  color: var(--BaseLink-Color);
}

.remove-medication-button {
  color: rgb(94, 94, 94);
}

.link-color {
  color: var(--BaseLink-Color);
}
</style>
