<template>
  <div
    class="flex flex-col px-2 rounded leading-6 toast"
    :class="[getClassForVariant(), getClassBasedOnDismissButton()]"
  >
    <div class="flex flex-row justify-end">
      <button
        @click="onDismissButtonClick"
        v-if="includeDismissButton"
        data-testid="toast-dismiss-button"
      >
        <Icon name="times" />
      </button>
    </div>
    <div
      class="flex flex-row mr-4 pl-5"
      :class="{ 'justify-center': isCentered }"
    >
      <Icon :name="getIconNameForVariant()" size="lg" type="far" class="mr-2" />
      <div class="flex flex-col">
        <p class="font-medium toast-title">{{ title }}</p>
        <p class="leading-6 font-normal toast-message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export const ToastVariants = {
  INFO: "INFO",
  ERROR: "ERROR",
};

export default {
  name: "Toast",
  components: { Icon },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    variant: {
      type: String,
      default: ToastVariants.INFO,
      validator: (value) => {
        return Object.keys(ToastVariants).indexOf(value) !== -1;
      },
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
    /**
     * Boolean to indicate whether a dismiss button should be included. If this is false, the state manager of the Toast
     * should handle remove the Toast when applicable.
     */
    includeDismissButton: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getIconNameForVariant() {
      switch (this.variant) {
        case ToastVariants.INFO: {
          return "check-circle";
        }
        case ToastVariants.ERROR: {
          return "ban";
        }
        default: {
          return "check-circle";
        }
      }
    },
    getClassForVariant() {
      switch (this.variant) {
        case ToastVariants.INFO: {
          return "toast-info";
        }
        case ToastVariants.ERROR: {
          return "toast-error";
        }
        default: {
          return "toast-info";
        }
      }
    },
    getClassBasedOnDismissButton() {
      if (this.includeDismissButton) {
        return "pt-2 pb-6";
      }

      return "pt-4 pb-4";
    },
    onDismissButtonClick() {
      this.$emit("on-toast-dismiss", this.id);
    },
  },
};
</script>

<style scoped>
.toast {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  width: calc(100vw - 32px);
}

.toast-title {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.toast-message {
  font-family: Roboto-Regular, sans-serif;
}

.toast-info {
  background-color: rgb(0, 67, 86);
  color: rgb(255, 255, 255);
}

.toast-error {
  background-color: rgb(169, 0, 0);
  color: rgb(255, 255, 255);
}

@media screen and (min-width: 800px) {
  .toast {
    width: auto;
  }
}
</style>
