export function lowPrecisionCurrencyOrNull(value) {
  if (!value) {
    return null;
  }

  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function number(value) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return null;
}

export default function attachAllFilters(Vue) {
  Vue.filter("lowPrecisionCurrencyOrNull", lowPrecisionCurrencyOrNull);
  Vue.filter("number", number);
}
