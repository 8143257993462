import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleLeft,
  faArrowDown,
  faArrowUp,
  faArrowToBottom,
  faBan,
  faBars,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faFilePdf,
  faInfoCircle,
  faSearch,
  faSort,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faArrowToBottom as falArrowToBottom,
  faTimes as falTimes,
} from "@fortawesome/pro-light-svg-icons";

import {
  faArrowDown as farArrowDown,
  faCheckCircle as farCheckCircle,
  faExclamationTriangle as farExclamationTriangle,
  faExternalLinkSquareAlt as farExternalLinkSquareAlt,
  faBan as farBan,
} from "@fortawesome/pro-regular-svg-icons";
/**
 * Function for registering all of the FontAwesome icons the app is using.
 */
function setupFontAwesome() {
  const solidIcons = [
    faAngleLeft,
    faArrowDown,
    faArrowUp,
    faArrowToBottom,
    faBan,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronUp,
    faExclamationTriangle,
    faFilePdf,
    faInfoCircle,
    faSearch,
    faSort,
    faSpinner,
    faTimes,
    faTimesCircle,
    faTrash,
  ];
  const lightIcons = [falArrowToBottom, falTimes];
  const regularIcons = [
    farArrowDown,
    farCheckCircle,
    farExclamationTriangle,
    farExternalLinkSquareAlt,
    farBan,
  ];
  const icons = [...solidIcons, ...lightIcons, ...regularIcons];
  icons.forEach((icon) => library.add(icon));
  Vue.component("font-awesome-icon", FontAwesomeIcon);
}
export default setupFontAwesome;
