<template>
  <div
    class="flex flex-col justify-center px-8 md:px-6 rounded-lg sign-into-rx-savings-card"
  >
    <p class="mb-4 font-medium leading-normal sign-into-rx-savings-description">
      {{ $t("SignIntoRxSavingsCard.SignInToRxDescription") }}
    </p>
    <a
      href="https://portal.rxsavingssolutions.com/"
      class="font-medium sign-into-rx-savings-link"
      ><span class="mr-2 leading-6">{{
        $t("SignIntoRxSavingsCard.SignInToRxLink")
      }}</span
      ><Icon type="far" name="external-link-square-alt"
    /></a>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "SignIntoRxSavingsCard",
  components: {
    Icon,
  },
};
</script>

<style scoped>
.sign-into-rx-savings-card {
  min-height: 140px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  color: rgb(26, 26, 26);
}

.sign-into-rx-savings-description {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.sign-into-rx-savings-link {
  font-family: Roboto-Medium, sans-serif;
  color: var(--BaseLink-Color);
}
</style>
