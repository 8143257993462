<template>
  <span
    class="font-semibold leading-4 text-xs rounded-sm px-1 whitespace-no-wrap not-covered-tag"
    >{{ $t("NotCoveredTag.Message") }}</span
  >
</template>

<script>
export default {
  name: "NotCoveredTag",
};
</script>

<style scoped>
.not-covered-tag {
  width: min-content;
  background-color: rgb(242, 159, 54);
  color: rgb(90, 30, 0);
}
</style>
