<template>
  <tr>
    <td :colspan="numberOfColumns">
      <slot>
        <div
          class="DataTable-NoContentPlaceholder"
          data-testid="DataTable-NoContentPlaceholder"
        ></div>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "EmptyRow",
  props: {
    numberOfColumns: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
