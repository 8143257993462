<template>
  <div class="py-8 pl-8 rounded-lg w-full rx-savings-footer" v-if="WhiteLabelSettings.showStandAloneRxSavingsSolutionsFooter">
    <div class="mb-8">
      <p class="leading-4 text-sm">
        {{ $t("RxSavingsFooter.PoweredBy") }}
        <span class="invisible">
          {{ $t("RxSavingsFooter.RxSavingsSolutions") }}
        </span>
      </p>
      <img :src="logoSvg" :alt="$t('RxSavingsFooter.LogoAlt')" class="w-32 mb-2" />
      <span class="text-sm leading-6 whitespace-no-wrap">{{ copyRight }}</span>
    </div>
    <div class="leading-tight rx-savings-footer-links">
      <div>
        <a target="_blank" href="https://rxsavingssolutions.com/company/about-us" class="footer-link"
          ><div class="footer-link-text">
            <span>{{ $t("RxSavingsFooter.AboutLink") }}</span>
          </div>
          <Icon type="far" name="external-link-square-alt" class="external-link-icon"
        /></a>
      </div>
      <div>
        <a target="_blank" href="https://rxsavingssolutions.com/terms-and-conditions" class="footer-link">
          <div class="footer-link-text">
            <span>{{ $t("RxSavingsFooter.RxSavingsSolutions") }} </span>
            <span>{{ $t("RxSavingsFooter.TermsAndConditionsLink") }}</span>
          </div>
          <Icon type="far" name="external-link-square-alt" class="external-link-icon"
        /></a>
      </div>
      <div>
        <a target="_blank" href="https://rxsavingssolutions.com/privacy-policy" class="footer-link">
          <div class="footer-link-text">
            <span>{{ $t("RxSavingsFooter.RxSavingsSolutions") }} </span>
            <span>{{ $t("RxSavingsFooter.PrivacyPolicyLink") }}</span>
          </div>
          <Icon type="far" name="external-link-square-alt" class="external-link-icon"
        /></a>
      </div>
      <div>
        <a target="_blank" href="https://rxsavingssolutions.com/notice-of-privacy-practices" class="footer-link">
          <div class="footer-link-text">
            <span>{{ $t("RxSavingsFooter.RxSavingsSolutions") }} </span>
            <span>{{ $t("RxSavingsFooter.NoticeOfPrivacyPracticesLink") }}</span>
          </div>
          <Icon type="far" name="external-link-square-alt" class="external-link-icon"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { planYear } from "@/util/getMultiPlanYearData";

export default {
  name: "RxSavingsFooter",
  inject: ["WhiteLabelSettings"],
  components: {
    Icon,
  },
  data() {
    return {
      copyRight: this.$t("RxSavingsFooter.Copyright", [planYear.THIS_YEAR]),
    };
  },
  computed: {
    logoSvg() {
      return require("@/assets/logo.svg");
    },
  },
};
</script>

<style scoped>
.rx-savings-footer {
  color: rgb(26, 26, 26);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.rx-savings-footer-links {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-row-gap: 24px;
  color: var(--BaseLink-Color);
}

.footer-link {
  display: flex;
}

.footer-link-text {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  white-space: nowrap;
}

.external-link-icon {
  position: relative;
  top: 2px;
}

@media screen and (min-width: 800px) {
  .rx-savings-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rx-savings-footer-links {
    margin-left: 40px;
  }

  .rx-savings-footer-links {
    grid-template-columns: repeat(auto-fill, 200px);
    grid-template-rows: auto;
    grid-column-gap: 32px;
  }
}
</style>
