<template>
  <div
    class="px-8 md:px-6 py-6 rounded-lg url-card"
    :class="{ 'url-card-for-ie': isUsingInternetExplorer }"
  >
    <Icon name="file-pdf" size="lg" class="file-icon" />
    <span class="font-light leading-6 h-full description">
      {{ $t("SelfHostedFormularyUrlCard.Description") }}
    </span>
    <div class="url-card-button-container">
      <a
        class="flex justify-center items-center w-32 font-normal text-sm leading-6 rounded-full url-link"
        :href="selfHostedFormularyUrl"
        target="”_blank”"
      >
        {{ $t("SelfHostedFormularyUrlCard.Button") }}
      </a>
    </div>
  </div>
</template>

<script>
import { detect } from "detect-browser";
import Icon from "@/components/Icon";

/**
 * Card for directing users to a single URL that holds all of the client's formularies.
 */
export default {
  name: "SelfHostedFormularyUrlCard",
  components: { Icon },
  props: {
    selfHostedFormularyUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    isUsingInternetExplorer() {
      const browser = detect();

      return browser && browser.name === "ie";
    },
  },
};
</script>

<style scoped>
.url-card {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  min-height: 84px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  color: rgb(26, 26, 26);
  display: grid;
  grid-template-areas:
    "icon description"
    "- button";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: min-content 1fr;
}

.file-icon {
  grid-area: icon;
}

.description {
  grid-area: description;
  padding-left: 16px;
}

.url-card-button-container {
  grid-area: button;
  margin-left: 16px;
  padding-top: 16px;
}

.url-card-button {
  background-color: var(--BaseButton-BackgroundColor);
  color: var(--BaseButton-Color);
}

.url-link {
  min-height: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  background: var(--BaseButton-BackgroundColor);
  color: var(--BaseButton-Color);
}

@media screen and (min-width: 800px) {
  .url-card {
    grid-template-areas: "icon description button";
    grid-template-columns: min-content auto min-content;
    justify-content: center;
    align-items: center;
  }

  .url-card > * {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  .url-card-button-container {
    grid-row: 1;
    padding-top: 0;
  }
}

.url-card-for-ie {
  display: flex;
}
</style>
