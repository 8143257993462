<template>
  <div class="formulary-type-picker">
    <div style="min-width: 150px">
      <p class="mb-1 font-light formulary-type-heading">{{ $t("FormularyTypePicker.InsurancePlanType") }}</p>
      <div>
        <ListBox
          data-testid="formulary-type-select"
          labelled-by="Formulary Type Select"
          label="Formulary Types"
          @input="(value) => onFormularyTypeSelect(value)"
          :value="getSelectedFormularyType()"
          :options="getAvailableFormularyTypes()"
        >
          <template slot="trigger" slot-scope="{ onClick, isOpen, disabled, labelledBy }">
            <div
              :class="{
                'listbox-trigger-button-wrapper': isOpen,
                'listbox-trigger-button-wrapper-closed': !isOpen,
              }"
            >
              <button
                @click="onClick"
                ref="listboxTriggerButton"
                class="leading-normal text-left py-2 w-full rounded-sm items-center justify-between listbox-trigger-button"
                :class="{
                  'listbox-trigger-button-focus-outline': isOpen,
                  'listbox-trigger-button-disabled': disabled,
                }"
                aria-haspopup="listbox"
                :aria-expanded="isOpen"
                :aria-labelledby="labelledBy"
                :aria-disabled="disabled"
                data-testid="selected-formulary-button"
              >
                <div class="flex flex-row formulary-heading">
                  {{ getSelectedFormularyType() }}
                  <Icon
                    name="chevron-down"
                    class="listbox-trigger-button-chevron ml-2"
                    :class="{
                      'listbox-trigger-button-chevron-disabled': disabled,
                    }"
                    v-if="!isOpen"
                  />
                  <Icon name="chevron-up" class="listbox-trigger-button-chevron ml-2" v-else />
                </div>
                <div>
                  <hr :class="{ 'hr-underline-is-hidden': isOpen }" />
                </div>
              </button>
            </div>
          </template>
        </ListBox>
      </div>
    </div>
  </div>
</template>

<script>
import ListBox from "@/components/ListBox/ListBox";
import Icon from "@/components/Icon";

export default {
  name: "FormularyTypePicker",
  components: { ListBox, Icon },
  props: {
    selectedFormularyTypeIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      indexOfFormularyTypeSelected: undefined,
      formularyTypes: [
        {
          id: 0,
          description: "Commercial",
        },
        // Remove Medicare option until Medicare formularies are included
        // {
        //   id: 1,
        //   description: "Medicare",
        // },
      ],
    };
  },
  methods: {
    getAvailableFormularyTypes() {
      return this.formularyTypes.map((formularyType) => formularyType.description);
    },
    getSelectedFormularyType() {
      return this.selectedFormularyTypeIndex != null ? this.formularyTypes[this.selectedFormularyTypeIndex].description : "Select";
    },
    onFormularyTypeSelect(value) {
      const typeIndex = this.formularyTypes.findIndex((formularyType) => formularyType.description === value);

      this.indexOfFormularyTypeSelected = typeIndex;
      this.$emit("formulary-type-selected", { typeIndex });
    },
  },
};
</script>

<style scoped>
.formulary-type-picker {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.formulary-type-heading {
  color: var(--LandingPage-SearchContainer-Description-TextColor);
}

.listbox-trigger-button {
  width: auto;
  color: var(--BaseButton-Color);
  background: none;
  font-weight: 300;
}

.listbox-trigger-button-wrapper {
  color: var(--BaseButton-Color);
  font-weight: 300;
  box-shadow: none;
}

.listbox-trigger-button-wrapper-closed {
  color: var(--BaseButton-Color);
  font-weight: 300;
}

.listbox-trigger-button-chevron {
  color: var(--BaseButton-Color);
  font-size: 16px;
  font-weight: 300;
  margin-top: auto;
  margin-bottom: auto;
}

.formulary-heading {
  font-size: 1.5rem;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: 300;
  font-style: normal;
}

hr {
  background-color: var(--BaseButton-Color);
  border: 0 none;
  height: 1px;
}

.hr-underline-is-hidden {
  background-color: unset;
}

.listbox-trigger-button-wrapper-closed:hover hr {
  visibility: hidden;
}

.listbox-trigger-button:focus hr {
  visibility: hidden;
}

.listbox-trigger-button:focus {
  outline: 0;
  box-shadow: none;
}

.listbox-trigger-button-focus-outline {
  box-shadow: none;
}
</style>

<style>
.formulary-type-picker .listbox-selected-option {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: bold;
}

.formulary-type-picker .listbox-list-container {
  padding-top: 0px;
}

.formulary-type-picker .listbox-option {
  font-size: 1rem;
}

.formulary-type-picker .listbox-active-option {
  background-color: #f5f5f5;
}
</style>
