<template>
  <div
    class="bg-white w-full rounded overflow-y-auto listbox-dropdown"
    tabindex="-1"
    data-testid="listbox-menu"
  >
    <div class="listbox-list-header">
      <div class="flex items-end justify-end w-full pt-4 pr-4">
        <button
          @click="onCloseIconClick"
          :aria-label="$t('ListBoxList.CloseIconAriaLabel', [label])"
          data-testid="listbox-close-button"
        >
          <Icon name="times" class="listbox-close-icon" />
        </button>
      </div>
      <label class="mb-4 block listbox-list-label">{{ label }}</label>
    </div>
    <ul
      class="scroll-list"
      role="listbox"
      :aria-labelledby="labelledBy"
      ref="dropdown"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "ListboxList",
  props: {
    /**
     * HTML Id to identify the element that provides context for / labels this ListBox.
     */
    labelledBy: {
      type: String,
      required: true,
    },

    /**
     * String of the text-content for a label that will be displayed when the ListBox is opened on mobile.
     */
    label: {
      type: String,
      required: true,
    },
  },
  components: { Icon },
  methods: {
    onCloseIconClick() {
      this.$emit("close-icon-click");
    },
  },
};
</script>

<style scoped>
.listbox-close-icon {
  height: 24px;
  width: 15px;
}

.listbox-dropdown {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  max-height: 136px;
  scroll-snap-type: y mandatory;
  z-index: 20;
}

.listbox-list-header {
  display: none;
}

.listbox-list-label {
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.listbox-dropdown:focus {
  outline: 0;
}

@media screen and (max-width: 800px) {
  .listbox-list-header {
    font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: #fff;
    height: 80px; /* Set a height so that we can calculate where the scroll list should start */
  }

  .listbox-list-label {
    padding-left: 16px;
    text-align: left;
  }

  .listbox-dropdown {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    max-height: none;
    border-radius: 0;
    z-index: 50;
  }

  .scroll-list {
    height: calc(100vh - 80px); /* Based on height of .listbox-list-header */
    top: 80px; /* Based on height of .listbox-list-header */
    overflow-y: auto;
    width: 100%;
    position: fixed;
  }
}
</style>
