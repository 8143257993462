/**
 * Returns a formatted string to display the related results of a medication. This function uses the i18n utilities,
 * and therefore should only be used when bound to a Vue component.
 *
 * @param {Object} medication - The medication object to get the formatted string for.
 *
 * @returns {String} - A formatted i18n string.
 */
export function getRelatedNamesLabel(medication) {
  if (!medication.relatedNames || medication.relatedNames.length === 0) {
    return undefined;
  }

  if (medication.isGeneric) {
    return this.$t("RelatedNamesLabel.genericOfLabel", [
      medication.relatedNames.join(", "),
    ]);
  }

  return this.$t("RelatedNamesLabel.brandNamesLabel", [
    medication.relatedNames.join(", "),
  ]);
}
